import { useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";

export const useViewPortLoaded = () => {
  const { width, height } = useViewportSize();
  const [isLoaded, setIsloaded] = useState(false);
  useEffect(() => {
    if(width!==0 & height!==0){
      setIsloaded(true);
    }
  }, [width, height]);
  return {width, height, isLoaded};
}