import { Container, Flex, useMatches } from "@mantine/core";

export const ContentContainer = ({ children, bg, h = "fit-conent", style }) => {
	const width = useMatches({
		sm: "100%",
		md: "1440",
		lg: "100%",
		xl: "1920",
	});

	const pt = useMatches({
		sm: 56 + 24,
		md: 80 + 24,
		lg: 80 + 24,
		xl: 80 + 24,
	});

	//padding horizon
	const ph = useMatches({
		sm: 24,
		md: 24,
		lg: 24,
		xl: 24,
	});

	//padding bottom
	const pb = useMatches({
		sm: 0,
		md: 48,
		lg: 48,
		xl: 96,
	});

	return (
		<Flex
			direction={"column"}
			style={style}
			pt={pt}
			pl={ph}
			pb={pb}
			pr={ph}
			w={width}
			h={h}
			className={'content_container'}
		>
			{children}
		</Flex>
	);
};
