module.exports = {
	// 12,14,16,20
	fontSizes: {
		xs: "0.6rem",
		sm: "0.75rem",
		md: "0.9rem",
		lg: "1rem",
		xl: "1.125rem",
	},
	headings: {
		sizes: {
			// Title With Product Fonts (multi languages)
			h1: {
				fontWeight: 900,
				fontSize: "3rem",
				lineHeight: 1.3,
			},
			h2: {
				fontWeight: 900,
				fontSize: "2rem",
				lineHeight: 1.5,
			},
			h3: {
				fontWeight: 900,
				fontSize: "1.5rem",
				lineHeight: 1.5,
			},
			h4: {
				fontWeight: 900,
				fontSize: "1.25rem",
				lineHeight: 1.5,
			},
			h5: {
				fontWeight: 900,
				fontSize: "0.95rem",
				lineHeight: 1.5,
			},
			h6: {
				fontWeight: 900,
				fontSize: "0.9rem",
				lineHeight: 1.28,
			},
		},
	},
};
