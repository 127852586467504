import { FullPageContainer } from "../layout/container/FullPageContainer";
import ViewportWrapper from "../triggerWrapper/ViewportWrapper";

export const SectionContainer = ({ children,onEnter,onLeave, bg, style }) => {
	return (
		<ViewportWrapper
			className={`section`}
      onEnterViewport={onEnter}
      onLeaveViewport={onLeave}
			style={style}
    >
			<FullPageContainer bg={bg} isMargin style={{ position: "relative", zIndex: 99, ...style }}>
				{children}
			</FullPageContainer>
		</ViewportWrapper>
	);
};