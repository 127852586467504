const link_c1_t = `M0,453.35h634.7c4.4,0,8-3.25,8-7.26v-26.48c0-4.01,3.6-7.26,8-7.26h391.3-22.6,27.1c4.4,0,8,3.25,8,7.26v108.48c0,4.01,3.6,7.26,8,7.26h49.5`;
const link_c1_b = `M0,371.35h634.7c4.4,0,8,3.25,8,7.26v26.48c0,4.01,3.6,7.26,8,7.26h391.3-22.6,27.1c4.4,0,8-3.25,8-7.26v-108.48c0-4.01,3.6-7.26,8-7.26h49.5`;
const link_c2_t = `M0,617.35h634.7c4.4,0,8-3.25,8-7.26v-190.48c0-4.01,3.6-7.26,8-7.26h391.3-22.6,27.1c4.4,0,8,3.25,8,7.26v397.62c0,4.01,3.6,7.26,8,7.26h857.5`;
const link_c2_b = `M0,207.35h634.7c4.4,0,8,3.25,8,7.26v190.48c0,4.01,3.6,7.26,8,7.26h391.3-22.6,27.1c4.4,0,8-3.25,8-7.26V7.76c0-4.01,3.6-7.26,8-7.26h857.5`;
const link_c3_t = `M0,535.35h634.7c4.4,0,8-3.25,8-7.26v-108.48c0-4.01,3.6-7.26,8-7.26h391.3-22.6,27.1c4.4,0,8,3.25,8,7.26v270.88c0,4.01,3.6,7.26,8,7.26h857.5`;
const link_c3_b = `M0,289.35h634.7c4.4,0,8,3.25,8,7.26v108.48c0,4.01,3.6,7.26,8,7.26h391.3-22.6,27.1c4.4,0,8-3.25,8-7.26V121.76c0-4.01,3.6-7.26,8-7.26l858.5,8`;

export const linkList = [
	link_c3_t,
	link_c3_b,
	link_c2_t,
	link_c2_b,
	link_c1_t,
	link_c1_b,
];
