import styled from "styled-components";
import { Container, Stack } from "@mantine/core";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import { SymbolWithAI } from "../../components/symbol/SymbolWithAI";
import { Flex, em } from "@mantine/core";
import Marquee from "react-fast-marquee";
import { Title } from "@mantine/core";
import { Image } from "@mantine/core";
import icon_finance from "../../assets/icon/brand/ic_finance.svg";
import icon_technology from "../../assets/icon/brand/ic_tech.svg";
import { useMediaQuery } from "@mantine/hooks";
import { useViewPortLoaded } from "../../hook/useViewPortLoaded";

const duration = "0.3s";

const Symbol = styled(Flex)`
	position: absolute;
	transform: ${(props) => `scale(${props.scale})`};
	transition:
		all ${duration},
		ease-out;
`;

const SymbolContainer = styled(Flex)`
	position: absolute;
	z-index: 99;
	opacity: ${(props) => (props.isTrigger ? (props.unmounted ? 0 : 1) : 0)};
	transition: all ${duration} ease-out ${(props) => props.delay};
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transform: ${(props) => `scale(${props.scale})`};
`;

const SymbolLeft = styled(Flex)`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: ${(props) => `${props.scale * 16}px`};
	height: ${(props) => `${props.scale * 16}px`};
	top: calc(50%);
	left: ${(props) => `calc(50% - ${props.size}px)`};
	background: ${(props) => props.accent};
	opacity: ${(props) => (props.isTrigger ? (props.unmounted ? 0 : 1) : 0)};
	transition: all ${duration} ease-out ${(props) => props.delay};
	z-index: 9;
`;

const SymboleRight = styled(Flex)`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: ${(props) => `${props.scale * 16}px`};
	height: ${(props) => `${props.scale * 16}px`};
	top: ${(props) => `calc(50% - ${props.size}px)`};
	left: calc(50%);
	background: ${(props) => props.accent};
	opacity: ${(props) => (props.isTrigger ? (props.unmounted ? 0 : 1) : 0)};
	transition: all ${duration} ease-out ${(props) => props.delay};
	z-index: 9;
`;

const Content = styled(Flex)`
	padding: 44px;
	@media only screen and (max-width: 750px) {
		padding: 12px 16px;
	}
	flex-direction: column;
	align-items: ${(props) => props.align};
	justify-content: start;
	width: 100%;
	height: 100%;
	opacity: ${(props) => (props.isTrigger ? (props.unmounted ? 0 : 1) : 0)};
	transition: all ${duration} ease-out ${(props) => props.delay};
`;

const LeftRow = styled(Flex)`
	position: absolute;
	z-index: 99;
	opacity: ${(props) => (props.isTrigger ? (props.unmounted ? 0 : 1) : 0)};
	top: ${(props) => `calc(50% - (${props.scale} * 16px) + 14px)`};
	left: 0;
	height: ${(props) => `${props.scale * 16}px`};
	width: calc(50% + 172px);
	transition: all ${duration} ease-out ${(props) => props.delay};
	-webkit-mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 1) 15%,
		rgba(0, 0, 0, 1) 85%,
		rgba(0, 0, 0, 0) 100%
	);
	mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 1) 15%,
		rgba(0, 0, 0, 1) 80%,
		rgba(0, 0, 0, 0) 100%
	);
	@media only screen and (max-width: 750px) {
		top: ${(props) => `calc(50% - (${props.scale} * 16px))`};
		padding-top: 88px;
		width: calc(50% + 88px);
	}
`;

const RightRow = styled(Flex)`
	position: absolute;
	opacity: ${(props) => (props.isTrigger ? (props.unmounted ? 0 : 1) : 0)};
	top: calc(50% + 14px);
	right: 0%;
	z-index: 99;
	height: ${(props) => `${props.scale * 16}px`};
	width: calc(50% + 172px);
	transition: all ${duration} ease-out ${(props) => props.delay};
	-webkit-mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 15%,
		rgba(0, 0, 0, 1) 85%,
		rgba(0, 0, 0, 1) 100%
	);
	mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 15%,
		rgba(0, 0, 0, 1) 80%,
		rgba(0, 0, 0, 1) 100%
	);
	@media only screen and (max-width: 750px) {
		padding-top: 88px;
		top: calc(50%);
		width: calc(50% + 88px);
	}
`;

const TextBox = styled(Flex)`
	align-items: center;
	justify-content: center;
	padding: 0px 32px;
	color: white;
	opacity: 1;
	color: #fff;
	/* text-shadow: 0px 0px 24px #00ffffa0; */
	@media only screen and (max-width: 750px) {
		padding: 0px 12px;
		height: 30px;
	}
`;

const WhiteBox = styled(Flex)`
	width: 160px;
	height: 64px;
	background: linear-gradient(45deg, #ffffff, #20a0ff);
	background-size: 400% 400%;
	animation: GradientBackground 3s ease infinite;
	position: relative;
	padding: 8px;
	@media only screen and (max-width: 750px) {
		width: calc(100% - 72px);
		height: 32px;
		padding: 4px;
	}

	@keyframes GradientBackground {
		0% {
			background-position: 0% 50%;
		}

		50% {
			background-position: 100% 50%;
		}

		100% {
			background-position: 0% 50%;
		}
	}
`;

// technology Right Top
const textList1 = [
	"#리스크관리",
	"#대안신용평가",
	"#사기방지",
	"#전략최적화",
	"#데이터결합",
	"#부실선행예측",
	"#신용관리",
	"#채널분석",
	"#조기경보",
];
// finance Left Bottom
const textList2 = [
	"#중금리대출",
	"#간편투자",
	"#소상공인",
	"#스타트업",
	"#임차인",
	"#공급망",
	"#씬파일러",
	"#대체투자",
];

export const TextLoopSection2 = ({
	isTrigger,
	unmounted,
	accent1 = "#00041a",
	accent2 = "#00041a",
	transitionDelay,
}) => {
	// Logo Setting
	const logoScale = {
		mb: 0.4,
		pc: 1,
	};
	const { width, height, isLoaded } = useViewPortLoaded();
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	return (
		<Container w={"100%"} style={{ position: "fixed", top: 0, left: 0 }}>
			{isLoaded && (
				<FullPageContainer style={{ position: "relative" }}>
					<SymbolContainer
						isTrigger={isTrigger}
						unmounted={unmounted}
						delay={transitionDelay}
						scale={isMobile ? width / 768 : 1}
					>
						{!unmounted && <SymbolWithAI scale={1} />}
					</SymbolContainer>
					<Symbol scale={isTrigger ? 1 : 1}>
						<SymbolLeft
							isTrigger={isTrigger}
							unmounted={unmounted}
							accent={accent2}
							scale={isMobile ? width / 2 / 16 : 24}
							size={isMobile ? width / 2 : 24 * 16}
							delay={transitionDelay}
							c={"blue.2"}
						>
							<Content gap={24} align={"flex-start"} isTrigger={isTrigger}>
								<Flex w={"100%"} direction={"column"} align={"start"} gap={8}>
									<Image src={icon_finance} w={isMobile ? 36 : 64} h={"auto"} />
									<Stack w={"100%"} gap={8}>
										<Title
											order={isMobile ? 5 : 3}
											lts={1}
											c={"#00AAFF"}
											fw={900}
											pb={isMobile ? 0 : 9}
										>
											모두를 위한 장벽없는
										</Title>
										<Flex
											w={"100%"}
											align={"center"}
											justify={"start"}
											gap={isMobile ? 8 : 24}
										>
											<Title
												size={isMobile ? "32px" : "64px"}
												lh={isMobile ? "32px" : "64px"}
												c={"#00aaff"}
												pt={isMobile ? 0 : 3}
											>
												금융
											</Title>
											<WhiteBox>
												<Flex bg={"#00041A"} w={"100%"} h={"100%"}></Flex>
											</WhiteBox>
										</Flex>
									</Stack>
								</Flex>
							</Content>
						</SymbolLeft>
						<SymboleRight
							isTrigger={isTrigger}
							unmounted={unmounted}
							accent={accent2}
							scale={isMobile ? width / 2 / 16 : 24}
							size={isMobile ? width / 2 : 24 * 16}
							delay={transitionDelay}
							c={"blue.2"}
						>
							<Content
								align={"flex-end"}
								w={"100%"}
								gap={24}
								isTrigger={isTrigger}
							>
								<Flex direction={"column"} w={"100%"} align={"end"} gap={8}>
									<Image
										src={icon_technology}
										w={isMobile ? 36 : 64}
										h={"auto"}
									/>
									<Stack gap={8} w={"100%"}>
										<Title
											order={isMobile ? 5 : 3}
											lts={1}
											ta={"end"}
											c={"#00AAFF"}
											fw={900}
											pb={isMobile ? 0 : 9}
										>
											금융현장에서 체감하는
										</Title>
										<Flex
											justify={"end"}
											align={"center"}
											w={"100%"}
											gap={isMobile ? 8 : 24}
										>
											<WhiteBox>
												<Flex bg={"#00041A"} w={"100%"} h={"100%"}></Flex>
											</WhiteBox>
											<Title
												size={isMobile ? "32px" : "64px"}
												lh={isMobile ? "32px" : "64px"}
												c={"#00aaff"}
												pt={isMobile ? 0 : 3}
											>
												기술
											</Title>
										</Flex>
									</Stack>
								</Flex>
							</Content>
						</SymboleRight>
					</Symbol>
					<LeftRow
						isTrigger={isTrigger}
						unmounted={unmounted}
						delay={transitionDelay}
						scale={isMobile ? width / 2 / 16 : 24}
					>
						<Marquee
							style={{ height: isMobile ? "30px" : "100%" }}
							autoFill
							direction="right"
						>
							{textList1.map((text, i) => (
								<TextBox key={i}>
									<Title order={isMobile ? 4 : 2}>{text}</Title>
								</TextBox>
							))}
						</Marquee>
					</LeftRow>
					<RightRow
						isTrigger={isTrigger}
						unmounted={unmounted}
						delay={transitionDelay}
						scale={isMobile ? width / 2 / 16 : 24}
					>
						<Marquee style={{ height: isMobile ? "30px" : "100%" }} autoFill>
							{textList2.map((text, i) => (
								<TextBox key={i}>
									<Title order={isMobile ? 4 : 2}>{text}</Title>
								</TextBox>
							))}
						</Marquee>
					</RightRow>
				</FullPageContainer>
			)}
		</Container>
	);
};
