import { useEffect, useState } from "react";
import { ContentContainer } from "../../components/container/ContentContainer";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import styled from "styled-components";
import { Flex } from "@mantine/core";
import { FadeInToTop } from "../../components/triggerWrapper/FadeInToTop";
import { DeviceFrame } from "../../components/container/DeviceFrame";
import { ScreenLending } from "./screen/ScreenLending";
import { ScreenHonest } from "./screen/ScreenHonest";
import { ScreenHonestLoan } from "./screen/ScreenHonestLoan";

const FloatingContainer = styled(FullPageContainer)`
	position: fixed;
	z-index: ${props => props.isTrigger?9:-1};
	left: ${(props) => props.left};
	top: 0;
	width: 50% !important;
	transition: all 0.4s ease-in-out;
	overflow: hidden;
`;

export const InteractiveScreen = ({
	isTrigger,
	currentSection,
	nextSection,
	currentVideo,
}) => {
	const screenList = [
		{
			title: "lending intelligence",
			w: 650,
			h: 370,
			position: "left",
			left: "0%",
		},
		{
			title: "honest fund",
			w: 390,
			h: 768,
			position: "left",
			left: "0%",
		},
		{
			title: "honest fund loan",
			w: 390,
			h: 768,
			position: "left",
			left: "0%",
		},
	];

	const [screenInfo, setScreenInfo] = useState(screenList[0]);

	useEffect(() => {
		if (2 < nextSection && nextSection < 6) {
			setScreenInfo(screenList[nextSection - 3]);
		}
	}, [nextSection]);

	return (
		<FloatingContainer left={screenInfo.left} isTrigger={isTrigger}>
			<Flex
				w={"100%%"}
				h={"100%"}
				direction={"column"}
				align={"center"}
				justify={"center"}
			>
				<FadeInToTop duration={"0.2s"} isTrigger={isTrigger}>
					{isTrigger && (
						<DeviceFrame w={screenInfo.w} h={screenInfo.h}>
							{nextSection === 3 && (
								<ScreenLending
									isTrigger={currentSection === 3}
									currentVideo={currentVideo}
								/>
							)}
							{nextSection === 4 && (
								<ScreenHonestLoan isTrigger={currentSection === 4} />
							)}
							{nextSection === 5 && (
								<ScreenHonest isTrigger={currentSection === 5} />
							)}
						</DeviceFrame>
					)}
				</FadeInToTop>
			</Flex>
		</FloatingContainer>
	);
};
