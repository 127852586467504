import { Flex } from "@mantine/core";
import { Title, em } from "@mantine/core";
import styled from "styled-components";
import { useMediaQuery } from "@mantine/hooks";

const Container = styled(Flex)`
	position: relative;
	${(props) =>
		props.position === "left" ? `padding-right: 28px;` : `padding-left: 28px;`};
	@media only screen and (max-width: 750px) {
		${(props) =>
			props.position === "left"
				? `padding-right: 20px;`
				: `padding-left: 20px;`};
	}
`;

const Mark = styled(Flex)`
	width: 8px;
	height: 8px;
	${(props) =>
		props.position === "left"
			? `
    top: 6px;
    right: 0px;
    `
			: `
    top: 6px;
    left: 0px;
    `}
	position: absolute;
	@media only screen and (max-width: 750px) {
		${(props) =>
			props.position === "left"
				? `
    top: 6px;
    right: -4px;
    `
				: `
    top: 6px;
    left: -4px;
    `}
	}
`;

export const HistoryList = ({ position, date, title }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	return (
		<Container
			w={isMobile ? "50%" : 288}
			c={"white"}
			position={position}
			direction={"column"}
		>
			<Mark position={position} bg={"blue.5"} />
			<Flex
				direction={"column"}
				justify={position === "left" ? "end" : "start"}
			>
				<Title
					order={isMobile ? 6 : 5}
					size={isMobile ? "14px" : "16px"}
					ta={position === "left" ? "right" : "left"}
					c={"blue.2"}
				>
					{date}
				</Title>
				<Title
					order={isMobile ? 6 : 5}
					size={isMobile ? "14px" : "16px"}
					ta={position === "left" ? "right" : "left"}
				>
					{title}
				</Title>
			</Flex>
		</Container>
	);
};
