import { useState } from "react";
import styled from "styled-components";
import { Flex, Stack } from "@mantine/core";
import { Image } from "@mantine/core";
import { Button } from "@mantine/core";
import { Title } from "@mantine/core";
import { Text, em } from "@mantine/core";
import { FadeInToTop } from "../triggerWrapper/FadeInToTop";
import { FadeInToRight } from "../triggerWrapper/FadeInToRight";
import { useMediaQuery } from "@mantine/hooks";
import { IconArrowRight } from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
import { Modal } from "@mantine/core";
import { brandFeatureMobileList } from "../../data/brandFeatureList";
import { blue } from "../../designTokens/colors";

const ImageBox = styled(Flex)`
	width: 156px;
	height: 300px;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: 750px) {
		width: 64px;
		height: 100%;
	}
`;

const TextBox = styled(Flex)`
	flex-direction: column;
	background-color: #030408;
	padding: 24px;
	width: calc(100% - 156px);
	@media only screen and (max-width: 750px) {
		width: calc(100% - 64px);
		padding: 16px;
	}
`;

const MoreButton = styled(Button)`
	position: absolute;
	right: 12px;
	bottom: 8px;
`;

const TabContainer = styled(Flex)`
	position: absolute;
	left: 0;
	bottom: 0;
`;

const ImageTab = styled(Flex)`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 64px;
	background-color: ${(props) =>
		props.currentIndex === props.index ? blue[6] : blue[2]};
`;

export const CardFeatureBig = ({
	title,
	overline,
	exp,
	img,
	isTrigger,
	index,
	delay,
}) => {
	const w = 680;
	const h = 316;
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const [isModal, setIsModal] = useState(false);
	const [featureIndex, setFeatureIndex] = useState(0);

	const ModalDetail = ({ opened, onClose }) => (
		<Modal
			opened={opened}
			onClose={onClose}
			size={"100%"}
			centered
			style={{ position: "relative" }}
		>
			<Flex
				p={20}
				pt={0}
				pb={80}
				direction={"column"}
				align={"center"}
				justify={"center"}
				gap={48}
			>
				<Stack gap={4}>
					<Title
						style={{ whiteSpace: "break-spaces" }}
						w={"100%"}
						ta={"left"}
						c={"blue.4"}
						order={5}
					>
						{brandFeatureMobileList[featureIndex].overline}
					</Title>
					<Title w={"100%"} ta={"left"} order={4}>
						{brandFeatureMobileList[featureIndex].title}
					</Title>
				</Stack>
				<Text style={{wordBreak: 'auto-phrase'}} ta={"left"} mb={24} size={isMobile ? "lg" : "xl"}>
					{brandFeatureMobileList[featureIndex].exp}
				</Text>
			</Flex>
			<TabContainer w={"100%"}>
				<ImageTab
					onClick={() => {
						setFeatureIndex(0);
					}}
					index={0}
					currentIndex={featureIndex}
				>
					<Image w={48} h={48} src={brandFeatureMobileList[0].img} />
				</ImageTab>
				<ImageTab
					onClick={() => {
						setFeatureIndex(1);
					}}
					index={1}
					currentIndex={featureIndex}
				>
					<Image w={48} h={48} src={brandFeatureMobileList[1].img} />
				</ImageTab>
			</TabContainer>
		</Modal>
	);

	return (
		<FadeInToRight isTrigger={isTrigger} delay={delay}>
			<ModalDetail
				opened={isModal}
				onClose={() => {
					setIsModal(false);
				}}
			/>
			<Flex
				w={isMobile ? "100%" : w}
				h={isMobile ? "auto" : h}
				direction={"row"}
				pb={isMobile ? 8 : 16}
				pl={isMobile ? 8 : 16}
				style={{ position: "relative" }}
				onClick={() => {
					if (!isMobile) return;
					console.log(index);
					setFeatureIndex(index);
					setIsModal(true);
				}}
			>
				<Flex w={"100%"} bg={"blue.9"}>
					<ImageBox>
						<Image src={img} w={isMobile ? 48 : 80} h={"auto"} />
					</ImageBox>
					<TextBox gap={isMobile ? 8 : 20}>
						<Flex direction={"column"} gap={8}>
							<Title order={isMobile ? 6 : 4} c={"#00aadd"}>
								{overline}
							</Title>
							<Title order={isMobile ? 4 : 2} pb={4} c={"white"}>
								{title}
							</Title>
						</Flex>
						{!isMobile && (
							<Text
								style={{ wordBreak: "break-word" }}
								c={"white"}
								size={isMobile ? "md" : "lg"}
							>
								{exp}
							</Text>
						)}
					</TextBox>
				</Flex>
				{isMobile && (
					<MoreButton
						size={"sm"}
						color={"white"}
						rightSection={<IconChevronRight size={18} />}
						variant={"subtle"}
					></MoreButton>
				)}

				{/* Mark */}
				<Flex
					bg={"#0044bb"}
					w={isMobile ? 8 : 16}
					h={isMobile ? 8 : 16}
					style={{ position: "absolute", left: 0, bottom: 0 }}
				></Flex>
			</Flex>
		</FadeInToRight>
	);
};
