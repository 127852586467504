import { useEffect, useState, useRef } from "react";
import { Flex, em } from "@mantine/core";
import { Image } from "@mantine/core";
import styled from "styled-components";
import { Title, AspectRatio } from "@mantine/core";
import img_app_bar from "../../../assets/screen/honestFundLoan/Top Bar.jpg";
import video_check from "../../../assets/screen/honestFundLoan/check_succeed_motion_320x320.mp4";
import img_loan_condition from "../../../assets/screen/honestFundLoan/loan_condition.png";
import img_loan_button from "../../../assets/screen/honestFundLoan/loan_button.png";
import { FadeInToTop } from "../../../components/triggerWrapper/FadeInToTop";
import { FadeIn } from "../../../components/triggerWrapper/FadeIn";
import { useMediaQuery } from "@mantine/hooks";
import img_check_complete from "../../../assets/animation/check_completed.gif";

const Container = styled(Flex)`
	border-radius: 8px;
	height: 100%;
	overflow: hidden;
	justify-content: space-between;
	padding-bottom: 24px;
`;

export const ScreenHonestLoan = ({ isTrigger }) => {
	const duration = "0.2s";
	const videoRef = useRef(null);

	// useEffect(() => {
	// 	if (isTrigger) {
	// 		videoRef.current.play();
	// 	}
	// }, [isTrigger]);

	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	return (
		<Container direction={"column"} align={"center"} w={390} bg={"white"}>
			<Image
				w={"calc(100% - 16px)"}
				ml={"auto"}
				mr={"auto"}
				h={"auto"}
				mt={4}
				src={img_app_bar}
			/>
			<FadeIn isTrigger={isTrigger} duration={duration}>
				<Flex direction={"column"} p={24} align={"center"}>
					<Title c={"gray.9"} order={isMobile ? 4 : 3} ta={"center"}>
						대출계약 체결이 완료되어 대출금이 입금될 예정이에요
					</Title>
				</Flex>
			</FadeIn>
			<FadeIn isTrigger={isTrigger} duration={duration}>
				<Image src={img_check_complete} w={isMobile?160:240} mb={isMobile?64:0} h={"auto"} />
			</FadeIn>
			<FadeIn isTrigger={isTrigger} duration={duration}>
				<Flex direction={"column"} pl={24} pr={24} gap={24}>
					<Image src={img_loan_condition} w={"100%"} h={"auto"} />
					<Image src={img_loan_button} w={"100%"} h={"auto"} />
				</Flex>
			</FadeIn>
		</Container>
	);
};
