import { Flex, em } from "@mantine/core";
import { Image } from "@mantine/core";
import styled from "styled-components";
import logo_dark from "../../assets/logo/logo-grid-mono-dark.svg";
import logo_light from "../../assets/logo/logo-grid-mono-light.svg";
import { Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { UnstyledButton } from "@mantine/core";
import { FadeInToRight } from "../triggerWrapper/FadeInToRight";
import { useMediaQuery } from "@mantine/hooks";
import { useDisclosure } from "@mantine/hooks";
import { Burger } from "@mantine/core";
import colors from "../../designTokens/colors";
import { FullPageContainer } from "../layout/container/FullPageContainer";

const Container = styled(Flex)`
	position: fixed;
	top: 0px;
	opacity: ${(props) => (props.isTrigger ? 1 : 0)};;
	left: 0px;
	z-index: 999;
	padding: 24px 48px;
	color: ${(props) =>
		props.nextSection < 3 || 5 < props.nextSection ? "#fff" : "#000"} !important;
	background-color: ${(props) =>
		props.nextSection < 3 || 5 < props.nextSection
			? "rgba(0, 8, 12, 0.85)"
			: "rgba(255, 255, 255, 0.15)"};
	backdrop-filter: ${(props) =>
		props.nextSection < 3 || 5 < props.nextSection
			? "blur(16px)"
			: "blur(64px)"};
	transition: all 0.3s ease-in-out;
	@media only screen and (max-width: 750px) {
		height: 64px;
		padding: 16px 24px;
	}
`;

const NavLink = styled(Text)`
	font-weight: 600;
	letter-spacing: 0.2px;
	font-size: 16px;
	font-family: "SF Pro Display";
	transition: all 0.2s ease-in-out;
	&:hover {
		color: #4d7cff;
	}
	@media only screen and (max-width: 750px) {
		font-size: 32px;
		font-weight: 700;
	}
`;

const MobileSlideMenu = styled(FullPageContainer)`
	z-index:9;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	padding: 24px;
	left: ${(props) => (props.opened ? "0%" : "-100%")};
	transition: all 0.3s ease-in-out;
	background-color: ${(props) =>
		props.nextSection < 3 || 5 < props.nextSection
			? "rgba(0, 0, 0, 1)"
			: "rgba(255, 255, 255, 1)"} !important;
`;

export const Header = ({ nextSection, onChangeSection, currentSection, isTrigger }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const [opened, { toggle }] = useDisclosure();

	const menuList = [
		{ title: "Philosophy", index: 1 },
		{ title: "Service", index: 4 },
		{ title: "History", index: 7 },
		{ title: "News", index: 8 },
		{ title: "Contact", index: 9 },
	];

	const Menu = ({ isTrigger, title, index }) => (
		<FadeInToRight isTrigger={isTrigger} delay={"0.5s"}>
			<UnstyledButton
				onClick={() => {
					onChangeSection(index);
					toggle();
				}}
			>
				<NavLink>{title}</NavLink>
			</UnstyledButton>
		</FadeInToRight>
	);
	return (
		<Container
			currentSection={currentSection}
			nextSection={nextSection}
			c={nextSection < 3 || 5 < nextSection ? "white" : "black"}
			w={"100%"}
			direction={"row"}
			justify={"space-between"}
			isTrigger={isTrigger}
		>
			<FadeInToRight isTrigger={isTrigger}>
				{nextSection < 3 || 5 < nextSection ? (
					<Image
						onClick={() => {
							onChangeSection(1);
						}}
						src={logo_dark}
						h={isMobile ? 14 : 20}
						mt={"auto"}
						mb={"auto"}
						w="auto"
					/>
				) : (
					<Image
						onClick={() => {
							onChangeSection(1);
						}}
						src={logo_light}
						h={isMobile ? 14 : 20}
						w="auto"
						mt={"auto"}
						mb={"auto"}
					/>
				)}
			</FadeInToRight>
			{!isMobile && (
				<Flex gap={24}>
					{menuList.map((menu, i) => (
						<Menu isTrigger={isTrigger} title={menu.title} index={menu.index} />
					))}
				</Flex>
			)}
			{isMobile && (
				<FadeInToRight isTrigger={isTrigger}>
					<Burger
						color={nextSection < 3 || 5 < nextSection ? "white" : "black"}
						opened={opened}
						onClick={toggle}
						style={{position:'absolute', zIndex:99, right:16, top:12}}
					/>
				</FadeInToRight>
			)}
			{isMobile && (
				<MobileSlideMenu opened={opened} nextSection={nextSection}>
					<Flex direction={"column"} gap={24}>
						{menuList.map((menu, i) => (
							<Menu
								isTrigger={isTrigger}
								title={menu.title}
								index={menu.index}
							/>
						))}
					</Flex>
				</MobileSlideMenu>
			)}
		</Container>
	);
};
