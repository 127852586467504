import { SectionContainer } from "../../components/container/SectionContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { Flex } from "@mantine/core";
import { Text, em } from "@mantine/core";
import styled from "styled-components";
import { FadeInToTop } from "../../components/triggerWrapper/FadeInToTop";
import ic_honest_fund from "../../assets/service/logo_honest_fund.svg";
import { ServiceInfo } from "./ServiceInfo";
import { useMediaQuery } from "@mantine/hooks";
import { DeviceFrame } from "../../components/container/DeviceFrame";
import { ScreenHonestLoan } from "./screen/ScreenHonestLoan";

const MobileScreenContainer = styled(Flex)`
	position: absolute;
	bottom: 80px;
	left: calc(50% - 160px);
	width: 320px;
	height: 320px;
	overflow: hidden;
	flex-direction: column;
	justify-content: flex-start;
	mask-image: linear-gradient(
			to top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 5%,
			rgba(0, 0, 0, 1) 15%,
			rgba(0, 0, 0, 1) 85%,
			rgba(0, 0, 0, 1) 100%
		);
`;

export const HonestFundLoanSection = ({ isTrigger, currentSection }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const description = `정밀한 신용평가를 통해 누구나 개인화된 맞춤형 대출을 편리하게 받을 수 있습니다. 고금리 대출의 부담은 줄고 대출기회가 없던 분들께는 새 기회를 제공합니다. 모바일 앱으로 1분만에 금리한도 조회가 가능하며 신용관리 서비스도 제공합니다.`;
	return (
		<SectionContainer>
			<ContentContainer>
				<Flex w={"100%"} h={"100%"}>
					{!isMobile && (
						<Flex
							w={"50%"}
							direction={"column"}
							align={"center"}
							justify={"center"}
						></Flex>
					)}
					<Flex
						w={isMobile ? "100%" : "50%"}
						direction={"column"}
						align={"center"}
						p={isMobile ? 24 : 0}
					>
						<FadeInToTop duration={"1s"} isTrigger={isTrigger}>
							<ServiceInfo
								logo={ic_honest_fund}
								serviceTitle={"어니스트펀드 / 대출"}
								color={"#0443A1"}
								isLicense
								title={isMobile?`은행 대출이 어려운 순간을 위한\n최적의 대출서비스`:`은행 대출이\n어려운 순간을 위한\n최적의 대출서비스`}
								title2={"어니스트펀드 / 대출"}
								description={description}
								isAppstore={false}
								isGooglePlay
								serviceType={'loan'}
							/>
						</FadeInToTop>
						{isMobile && (
							<MobileScreenContainer>
								<DeviceFrame w={320}>
									<ScreenHonestLoan isTrigger={currentSection === 4} />
								</DeviceFrame>
							</MobileScreenContainer>
						)}
					</Flex>
				</Flex>
			</ContentContainer>
		</SectionContainer>
	);
};
