const colors = require("./colors");

module.exports = {
  // Brand Color
	primary: colors.blue,
  // Supportive Color
	secondary: colors.ultramarine,
  highlight: colors.yellow,
  // Inform color - Simplifed shades of Supportive Color
  datumWhite: [colors.gray[0]],
  datumBlack: [colors.gray[9]],
  inform: [
    colors.gray[0],
    colors.gray[5],
    colors.gray[5],
    colors.gray[5],
    colors.gray[7],
    colors.gray[8],
    colors.gray[9],
    colors.gray[9],
    colors.gray[9],
    colors.gray[9],
  ],
  // When Background Dark Scheme
  contrast: [
    colors.white[0],
    colors.white[0],
    colors.white[0],
    colors.white[0],
    colors.white[0],
    colors.white[0],
    colors.white[1],
    colors.white[1],
    colors.white[1],
    colors.white[1],
  ],
  // Error
	desctructive: colors.red,
  // Warn
  warn: colors.orange,
  // Success
  success: colors.green,
  // Supportive
  supportive: colors.gray,
};
