
export const FadeInToTop = ({
	isTrigger,
	duration = '0.4s',
	delay= '0s',
	children,
	amount='80px',
	timingFunction = "ease-in-out",
	style
}) => {
	return (
		<div
			style={{opacity:isTrigger?1:0, marginBottom:isTrigger?0:amount, transition: `all ${duration} ${timingFunction} ${delay}`,...style }}
		>
			{children}
		</div>
	);
};
