import styled from "styled-components";
import { useEffect, useState } from "react";
import { Flex, Title, Tabs, em } from "@mantine/core";
import { ContentContainer } from "../../components/container/ContentContainer";
import { CardNews } from "../../components/card/CardNews";
import { mediaList } from "../../data/mediaList";
import { Grid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Button } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { IconChevronUp } from "@tabler/icons-react";
import { useViewPortLoaded } from "../../hook/useViewPortLoaded";

const PanelContainer = styled(Flex)`
	width: 100%;
	height: ${(props) => (props.isExtent ? "auto" : "calc(398px * 2)")};
	overflow: hidden;
	@media only screen and (max-width: 750px) {
		height: ${(props) => (props.isExtent ? "auto" : "calc(184px * 3)")};
	}
`;

export const MediaSection = ({}) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const { width, isLoaded } = useViewPortLoaded();

	const tabListPC = ["All", "Company", "Tech", "Interview", "Award"];
	const tabListMobile = ["Company", "Tech", "Interview", "Award"];

	const [tabList, setTablist] = useState([]);
	const [isExtent, setIsExtent] = useState(false);

	useEffect(() => {
		if (isLoaded) {
			if (isMobile) {
				setTablist(tabListMobile);
			} else {
				setTablist(tabListPC);
			}
		}
	}, [isLoaded]);

	return (
		<Flex direction={"column"} align={"center"}>
			{tabList.length !== 0 && (
				<ContentContainer>
					<Flex
						direction={"column"}
						align={"center"}
						w={isMobile ? "100%" : 1200}
						gap={isMobile ? 24 : 48}
						ml={"auto"}
						mr={"auto"}
						c={"white"}
						p={isMobile ? 24 : 0}
					>
						<Title
							order={isMobile ? 3 : 1}
							// size={isMobile ? 24 : 36}
							ta={"center"}
							lh={"64px"}
							c={"white"}
							mb={isMobile ? 0 : 24}
						>
							언론 속의 어니스트
							{}
						</Title>
						<Tabs w={"100%"} color={"white"} defaultValue={tabList[0]}>
							<Flex direction={"column"} w={"100%"} gap={20}>
								<Tabs.List w={"100%"}>
									{tabList.map((tab, i) => (
										<Tabs.Tab
											size={isMobile ? "xs" : "lg"}
											w={`calc(100%/${tabList.length})`}
											value={tab}
											key={i}
										>
											{tab}
										</Tabs.Tab>
									))}
								</Tabs.List>
								<Tabs.Panel value={tabList[0]}>
									<Flex
										w={"100%"}
										direction={"column"}
										align={"center"}
										gap={16}
									>
										<PanelContainer isExtent={isExtent}>
											<Grid>
												{mediaList
													.sort((a, b) => a.date - b.date)
													.map((item, i) => (
														<Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
															<CardNews
																date={item.date}
																category={item.category}
																title={item.title}
																media={item.media}
																key={i}
																link={item.link}
															/>
														</Grid.Col>
													))}
											</Grid>
										</PanelContainer>
										{!isMobile && (
											<Button
												variant="subtle"
												color={"white"}
												size={"xl"}
												w={isMobile ? "100%" : "160px"}
												rightSection={
													isExtent ? (
														<IconChevronUp size={16} />
													) : (
														<IconChevronDown size={16} />
													)
												}
												onClick={() => {
													if (!isExtent) {
														setIsExtent(true);
													} else {
														setIsExtent(false);
													}
												}}
											>
												{isExtent ? "숨기기" : "더보기"}
											</Button>
										)}
									</Flex>
								</Tabs.Panel>
								{tabList.map((tab, i) => (
									<Tabs.Panel value={tabList[i]}>
										<Grid>
											{i !== 0 &&
												mediaList
													.filter((a) => a.category === tabList[i])
													.sort((a, b) => a.date - b.date)
													.map((item, i) => (
														<Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
															<CardNews
																date={item.date}
																category={item.category}
																title={item.title}
																media={item.media}
																key={i}
																link={item.link}
															/>
														</Grid.Col>
													))}
										</Grid>
									</Tabs.Panel>
								))}
							</Flex>
						</Tabs>
					</Flex>
				</ContentContainer>
			)}
		</Flex>
	);
};
