module.exports = {
	displayHeadings: {
		// Title With Brand Fonts (only English)
		fontFamily: 'Hf Fintech gothic',
		d1: {
			fontWeight: 200,
			fontSize: "4.75rem",
			lineHeight: 1.56,
		},
		d2: {
			fontWeight: 200,
			fontSize: "3.75rem",
			lineHeight: 1.56,
		},
		d3: {
			fontWeight: 200,
			fontSize: "3rem",
			lineHeight: 1.56,
		},
		d4: {
			fontWeight: 200,
			fontSize: "2rem",
			lineHeight: 1.56,
		},
		d5: {
			fontWeight: 200,
			fontSize: "1.75rem",
			lineHeight: 1.56,
		},
	},
	size: {
		xs: "30rem",
		sm: "42rem",
		md: "64rem",
		lg: "90rem",
		xl: "120rem",
		topSectionHeight: "36rem"
	}
};