import styled from "styled-components";
import { Flex } from "@mantine/core";
import { Image } from "@mantine/core";
import { Title } from "@mantine/core";
import { Text } from "@mantine/core";
import { FadeInToRight } from "../triggerWrapper/FadeInToRight";
const ImageBox = styled(Flex)`
	width: 192px;
	height: 192px;
	align-items: center;
	justify-content: center;
`;

const TextBox = styled(Flex)`
	flex-direction: column;
	background-color: #030408;
	padding: 24px;
	width: calc(100% - 192px);
`;

export const CardFeature = ({ title, overline, exp, img, isTrigger,index,delay }) => {
	const w = 768;
	const h = 216;

	return (
		<FadeInToRight isTrigger={isTrigger} delay={delay}>
			<Flex
				w={w}
				h={h}
				direction={"row"}
				pb={16}
				pl={16}
				style={{ position: "relative" }}
			>
				<ImageBox bg={'blue.9'}>
					<Image src={img} w={80} h={"auto"} />
				</ImageBox>
				<TextBox gap={4}>
					<Title order={4} c={"#00aadd"}>
						{overline}
					</Title>
					<Title order={3} pb={4} c={"white"}>
						{title}
					</Title>
					<Text c={"gray.4"}>{exp}</Text>
				</TextBox>
				{/* Mark */}
				<Flex
					bg={"#0044bb"}
					w={16}
					h={16}
					style={{ position: "absolute", left: 0, bottom: 0 }}
				></Flex>
			</Flex>
		</FadeInToRight>
	);
};
