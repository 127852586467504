import { Flex } from "@mantine/core";
import styled from "styled-components";

const Path = styled.path`
	transform: ${(props) => `scale(${props.scale})`};
	transform-origin: 0% 0%;
`;

export const LogoMonoSvg = ({ scale }) => {
	return (
		<Flex>
			<svg
				width={640 * scale}
				height={104 * scale}
				viewBox={`0 0 ${640 * scale} ${104 * scale}`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<Path
					d="M85 101H68.9646V55.6845H16.0355V101H0V0H16.0355V42.8467H68.9646V0H85V101Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					d="M134.568 104C127.369 104 121.117 102.455 115.812 99.356C110.506 96.2657 106.378 91.8096 103.427 85.9875C100.476 80.1655 99 73.1483 99 64.9274C99 56.7066 100.476 49.715 103.427 43.9442C106.378 38.1734 110.532 33.7428 115.88 30.644C121.228 27.5537 127.463 26 134.568 26C141.673 26 148.019 27.5451 153.325 30.644C158.63 33.7428 162.733 38.1734 165.641 43.9442C168.55 49.715 170 56.7151 170 64.9274C170 73.1398 168.55 80.1655 165.641 85.9875C162.733 91.8096 158.63 96.2657 153.325 99.356C148.019 102.446 141.767 104 134.568 104ZM134.568 91.1181C140.565 91.1181 145.298 88.9924 148.753 84.7497C152.216 80.5069 153.947 73.8995 153.947 64.9445C153.947 55.9895 152.19 49.4077 148.685 45.2076C145.179 41.0076 140.471 38.9075 134.568 38.9075C128.666 38.9075 123.932 41.0076 120.384 45.2076C116.827 49.4077 115.053 55.9895 115.053 64.9445C115.053 73.8995 116.827 80.5069 120.384 84.7497C123.932 89.001 128.666 91.1181 134.568 91.1181Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					d="M222.637 26.0085C230.726 26.0085 237.147 28.4001 241.883 33.1919C246.628 37.9837 249 44.5202 249 52.8015V101H232.823V56.1124C232.823 50.6823 231.499 46.4437 228.843 43.4052C226.196 40.3668 222.354 38.8433 217.334 38.8433C212.683 38.8433 208.73 40.4519 205.48 43.6776C202.223 46.9033 200.461 51.0908 200.177 56.2485V101H184V28.7661H200.177V38.1539C202.317 34.5622 205.291 31.6429 209.108 29.3874C212.916 27.132 217.428 26 222.637 26V26.0085Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					d="M344.339 48.8638C344.339 44.245 345.521 40.2323 347.884 36.8087C350.247 33.3936 353.561 30.7384 357.816 28.843C362.071 26.9477 366.986 26 372.543 26C381.062 26 387.775 28.0576 392.689 32.1642C397.595 36.2708 400.421 41.4702 401.166 47.7539L387.407 49.8286C386.756 46.5074 385.086 43.8522 382.406 41.863C379.718 39.8737 376.336 38.8833 372.26 38.8833C368.553 38.8833 365.659 39.6688 363.578 41.2397C361.498 42.8106 360.453 45.0731 360.453 48.0271C360.453 50.5201 361.121 52.4582 362.465 53.8498C363.809 55.2329 366.147 56.3428 369.478 57.171L383.93 60.7739C390.6 62.4387 395.275 64.8634 397.964 68.0479C400.652 71.2325 401.996 75.3732 401.996 80.4446C401.996 85.2513 400.695 89.4262 398.109 92.9864C395.515 96.5466 391.996 99.2701 387.552 101.157C383.108 103.052 378.057 104 372.406 104C363.33 104 356.078 102.062 350.658 98.1773C345.238 94.2927 342.019 89.0335 341 82.3827L356.009 79.8897C356.746 83.4926 358.647 86.2417 361.703 88.1285C364.76 90.0239 368.322 90.9715 372.406 90.9715C377.218 90.9715 380.668 89.9812 382.757 87.9919C384.838 86.0026 385.883 83.6292 385.883 80.8544C385.883 78.6346 385.257 76.8161 384.007 75.3818C382.757 73.9475 380.369 72.8205 376.85 71.9838L362.542 68.5175C356.523 67.0405 351.985 64.7353 348.929 61.5935C345.872 58.4516 344.339 54.2084 344.339 48.8468V48.8638Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					d="M315.113 78.9533C313.706 82.8546 311.63 85.8168 308.85 87.7973C305.736 90.0169 302.047 91.1181 297.783 91.1181C292.396 91.1181 287.969 89.3425 284.486 85.7826C281.003 82.2314 278.935 76.802 278.292 69.5031H333V61.8884C333 53.9492 331.421 47.3247 328.264 42.0063C325.107 36.6965 320.861 32.7013 315.525 30.0208C310.188 27.3403 304.269 26 297.775 26C290.628 26 284.366 27.5451 278.978 30.644C273.59 33.7428 269.421 38.1734 266.453 43.9442C263.484 49.715 262 56.7151 262 64.9274C262 73.1398 263.484 80.1655 266.453 85.9875C269.421 91.8096 273.573 96.2657 278.909 99.356C284.245 102.446 290.534 104 297.775 104C306.311 104 313.457 101.926 319.214 97.7682C324.472 93.9694 328.144 88.5998 330.298 81.7106L315.104 78.9533H315.113ZM285.181 43.3893C288.475 40.3929 292.67 38.8904 297.783 38.8904C303.54 38.8904 307.992 40.649 311.149 44.1576C314.306 47.6662 316.211 52.0114 316.854 57.1761L278.712 57.4493C279.733 51.0809 281.886 46.3942 285.189 43.3893H285.181Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M559.208 101H579L542.67 0H518.393L482 101H501.594L511.406 71.8759H549.396L559.208 101ZM516.706 55.8097L530.037 16.0578H530.773L544.113 55.8097H516.706ZM640 0V16H624V85H640V101H589V85H605V16H589V0H640Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					d="M422 87H438V41H454V28H438V10H422V28H407V41H422V87Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					d="M454 87H438V103H454V87Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
				<Path
					d="M470 71H454V87H470V71Z"
					fill="currentColor"
					transform={`scale(${scale})`}
				/>
			</svg>
		</Flex>
	);
};
