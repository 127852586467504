import styled from "@emotion/styled";
import { Logo } from "./Logo";
// import { LogoModular } from "./LogoModular";
import { LogoGrid } from "./LogoGrid";
import { Creator } from "./Creator";
import { Blinker } from "./Blinker";
import { BlinkerCustom } from "./BlinkerCustom";
import { Flex } from "@mantine/core";
import colors from "../../designTokens/colors";

const offsetCenter = {
	x: -134, // 320 - 438
	y: -35, // -54 + 16
};

const Container = styled.div`
	position: relative;
	display: flex;
	width: fit-content;
	/* overflow: hidden; */
	z-index: 999;
	width: 640px;
	height: 104px;
`;

const MaskingArea = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const ImageMask = styled.div`
	margin-left: 0px;
	margin-top: 0px;
	position: absolute;
	top: 0;
	left: 0;
	width: ${(props) => (props.isTrigger ? "100%" : "100%")};
	transition: all ${(props) => `${props.duration}s`};
	transition-timing-function: ${(props) => props.ease};
	opacity: ${(props) =>
		props.currentSection === 0 ? 1 : props.nextSection === 0 ? 1 : 0};
	/* transform: ${(props) => `scale(${props.scale})`}; */
	transform: ${(props) =>
		`translate(${props.isExpend ? offsetCenter.x * props.scale : 0}px, ${props.isExpend ? offsetCenter.y * props.scale : 0}px) scale(${props.scale})`};
	/* overflow: hidden; */
`;

const Mark = styled.div`
	position: absolute;
	width: 4px;
	height: 4px;
	background-color: red;
	top: calc(50% - 2px);
	left: calc(50% - 2px);
	z-index: 999;
`;

const MovingContainer = styled.div`
	display: flex;
	position: absolute;
	left: -32px;
	bottom: 0px;
	opacity: ${(props) => (props.isTrigger ? "1" : "0")};
	left: ${(props) => (props.isTrigger ? "100%" : "0%")};
	transition: all ${(props) => `${props.duration}s`};
	transition-timing-function: ${(props) => props.ease};
	z-index: 9;
`;

export const LogoInteractiveExpandGrid = ({
	scale = 1,
	isTrigger = true,
	duration = 0.45,
	accentColor = colors.blue[3],
	isExpend,
	currentSection,
	nextSection,
}) => {
	const ease = "cubic-bezier(.91,0,.54,.99)";
	const blinkerList = [
		{ x: .5, y: 68, scale: 17 / 16 },
		{ x: 54.5, y: 28, scale: 14 / 16 },
		{ x: 408, y: 14.5, scale: 14 / 17 },
		{ x: 438, y: 71, scale: 1 },
		{ x: 589, y: 69, scale: 1 },
		{ x: 608, y: 0, scale: 1 },
	];

	const blinkerCustomList = [
		{ x: 0, y: 85, unit1: 17, unit2: 0 },
		{ x: 54.5, y: 25, unit1: 14, unit2: 17 },
		// { x: 408, y: 14.5, unit1: 16, unit2: 16 },
		{ x: 438, y: 71, unit1: 16, unit2: 16 },
		{ x: 589.5, y: 67, unit1: 16, unit2: 18 },
		{ x: 605.5, y: 0, unit1: 18, unit2: 16 },
	];

	return (
		<Container>
			<MaskingArea></MaskingArea>
			<ImageMask
				isTrigger={isTrigger}
				scale={scale}
				isExpend={isExpend}
				currentSection={currentSection}
				nextSection={nextSection}
				duration={duration}
				ease={ease}
			>
				<LogoGrid ease={ease} />
				{/* {blinkerList.map((b, i) => (
					<Flex
						style={{
							position: "absolute",
							top: b.y,
							left: b.x,
							opacity: isTrigger ? 1 : 0,
						}}
					>
						<Blinker
							isTrigger={isTrigger}
							id={i}
							startDelay={Math.random() * 3200 + 1200}
							accentColor={accentColor}
							scale={b.scale}
						/>
					</Flex>
				))} */}
				{blinkerCustomList.map((b, i) => (
					<Flex
						style={{
							position: "absolute",
							top: b.y,
							left: b.x,
							opacity: isTrigger ? 1 : 0,
						}}
					>
						<BlinkerCustom
							isTrigger={isTrigger}
							id={i}
							startDelay={Math.random() * 3200 + 1200}
							accentColor={accentColor}
							unit1={b.unit1}
							unit2={b.unit2}
						/>
					</Flex>
				))}
			</ImageMask>
		</Container>
	);
};
