import styled from "styled-components";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { Flex, em } from "@mantine/core";
import { ScreenIntro } from "./ScreenIntro";
import { Title, Text, Stack } from "@mantine/core";
import { DeviceFrame } from "../../components/container/DeviceFrame";
import { Grid, Button, Divider, Image } from "@mantine/core";
import logo_dark from "../../assets/logo/logo-grid-mono-dark.svg";
import { LogoMonoSvg } from "../../components/symbol/LogoMonoSvg";
import { List } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { useClipboard } from "@mantine/hooks";
import { useMediaQuery } from "@mantine/hooks";
import { useViewportSize } from "@mantine/hooks";

const Footer = () => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	return (
		<Flex
			direction={"column"}
			w={"100%"}
			bg={"gray.9"}
			gap={8}
			c={"gray.3"}
			p={isMobile ? 24 : 48}
			pt={isMobile ? 48 : 72}
			pb={isMobile ? 48 : 72}
			// style={{ position: "absolute", left: 0, bottom: 0 }}
		>
			{/* Business Info */}
			<Flex w={"100%"} gap={isMobile ? 8 : 12} wrap={"wrap"} align={"center"}>
				<Text size={isMobile ? "md" : "lg"}>(주)어니스트에이아이</Text>
				<Text size={isMobile ? "md" : "lg"}>|</Text>
				<Text size={isMobile ? "md" : "lg"}>대표 서상훈</Text>
				{!isMobile && <Text size={isMobile ? "md" : "lg"}>|</Text>}

				<Text size={isMobile ? "md" : "lg"}>사업자등록번호 184-81-00063</Text>
			</Flex>
			{/* Company Info */}
			<Flex w={"100%"} wrap={"wrap"} gap={isMobile ? 12 : 16} align={"center"}>
				<Text size={isMobile ? "md" : "lg"}>
					서울특별시 강남구 선릉로93길 40, 6층, 어니스트AI
				</Text>
				{!isMobile && <Text size={isMobile ? "md" : "xl"}>|</Text>}
				<Text size={isMobile ? "md" : "lg"}>문의 02-565-8856</Text>
			</Flex>
			<Divider color={isMobile ? "gray.6" : "gray.5"} mt={16} mb={16} />
			<Flex
				direction={isMobile ? "column" : "row"}
				w={"100%"}
				justify={"space-between"}
			>
				<Flex
					direction={isMobile ? "column-reverse" : "row"}
					gap={isMobile ? 16 : 24}
					align={isMobile ? "start" : "center"}
				>
					<LogoMonoSvg scale={0.18} />
					<Text size={isMobile ? "md" : "lg"} pt={2}>
						Copyright ⓒ 2024 HonestAI All rights reserved.
					</Text>
				</Flex>
				<Text
					mt={isMobile ? 16 : 0}
					td={"underline"}
					size={isMobile ? "lg" : "xl"}
				>
					<a
						href={"https://www.honestfund.kr/v2/terms/privacy"}
						target={"_blank"}
					>
						개인정보처리방침
					</a>
				</Text>
			</Flex>
		</Flex>
	);
};
const VideoContainer = styled(Flex)`
	border: 2px solid white;
	border-radius: 16px;
	overflow: hidden;
`;

export const FooterSection = ({ isTrigger }) => {
	const clipboard = useClipboard({ timeout: 500 });
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const { height, width } = useViewportSize();

	return (
		<FullPageContainer
			scale={1.1}
			style={{ position: "relative" }}
			// bg={"#091018"}
		>
			<ContentContainer>
				<Stack
					gap={isMobile ? 48 : 120}
					pt={isMobile ? 480 : 160}
					pb={isMobile ? 120 : 180}
				>
					<Flex
						direction={isMobile ? "column" : "row"}
						w={"100%"}
						h={"100%"}
						align={"center"}
						c={"white"}
						gap={24}
					>
						<Flex
							w={isMobile ? "100%" : "50%"}
							direction={"column"}
							align={"center"}
							justify={"center"}
						>
							<VideoContainer>
								<iframe
									width={isMobile ? width - 48 : 560 * 1.2}
									height={isMobile ? ((width - 48) * 315) / 560 : 315 * 1.2}
									src="https://www.youtube.com/embed/ayqyytq-m_s?si=-WqkIWRD0LMsM22C"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerpolicy="strict-origin-when-cross-origin"
									allowfullscreen
								></iframe>
							</VideoContainer>
						</Flex>
						<Flex
							pl={isMobile ? 24 : 0}
							pr={isMobile ? 24 : 0}
							w={isMobile ? "100%" : "50%"}
							direction={"column"}
							justify={"center"}
							align={"center"}
						>
							<Flex direction={"column"} w={isMobile ? "100%" : 480}>
								<Stack gap={isMobile ? 48 : 64} w={"100%"}>
									<Title order={isMobile ? 4 : 2}>
										어니스트AI는 금융시장의 문제를<br/>함께 풀어갈 파트너를 찾고
										있습니다
									</Title>
									<List
										size={isMobile ? "lg" : "xl"}
										spacing={isMobile ? "sm" : "md"}
									>
										<List.Item icon={<IconCheck w={20} h={20} />}>
											최신 AI 기술로 여신전략을 혁신하고 싶은
											금융사(은행/보험/카드/캐피탈/저축은행)
										</List.Item>
										<List.Item icon={<IconCheck w={20} h={20} />}>
											새로운 데이터로 대안적인 신용평가모형을 개발하고 싶은
											혁신사업자(핀테크/프롭테크/플랫폼)
										</List.Item>
										<List.Item icon={<IconCheck w={20} h={20} />}>
											리스크관리 분야에서 국내 최고를 넘어 세계적인 AI 회사를
											만들고자하는 인재(기술/금융/사업)
										</List.Item>
									</List>
									<Grid w={"100%"} gap={8}>
										<Grid.Col span={isMobile ? 12 : 6}>
											<Button
												onClick={() => {
													console.log("click");
													clipboard.copy("marketsolutions@honestai.tech");
													notifications.show({
														title: "솔루션 문의 메일이 복사되었습니다",
														message: "marketsolutions@honestai.tech",
													});
												}}
												size="xl"
												fullWidth
												variant="filled"
											>
												솔루션 문의하기
											</Button>
										</Grid.Col>
										<Grid.Col span={isMobile ? 12 : 6}>
											<Button
												size="xl"
												fullWidth
												color={"white"}
												variant="outline"
												onClick={() => {
													console.log("click");
													clipboard.copy("people@honestai.tech");
													notifications.show({
														title: "채용 문의 메일이 복사되었습니다",
														message: "people@honestai.tech",
													});
												}}
											>
												채용 문의하기
											</Button>
										</Grid.Col>
									</Grid>
								</Stack>
							</Flex>
						</Flex>
					</Flex>
				</Stack>
			</ContentContainer>
			<Footer />
		</FullPageContainer>
	);
};
