import { Transition } from "@mantine/core";

export const FadeIn = ({
	isTrigger,
	duration = "0.4s",
	delay = "0s",
	children,
	timingFunction = "ease-in-out",
	style
}) => {
	return (
		<div
			style={{
				opacity: isTrigger ? 1 : 0,
				transition: `all ${duration} ${timingFunction} ${delay}`,
				...style
			}}
		>
			{children}
		</div>
	);
};
