import styled from "@emotion/styled";
import logo_light from "../../assets/logo/logo-grid-8x-light.svg";
import logo_dark from "../../assets/logo/logo-grid-8x-dark.svg";
import { Image } from "@mantine/core";

const MaskedImage = styled(Image)`
	/* mask-image: ${(props) =>
		props.isTrigger
			? "linear-gradient(to right, black 100%, transparent 0%)"
			: "linear-gradient(to right, black 0%, transparent 0%)"}; */
	opacity: ${(props) => (props.isTrigger ? 1 : 0)};
	transition: all 0.6s;
	transition-timing-function: ${(props) => props.ease};
`;

export const Logo = ({ scale = 1, mode = "dark", isTrigger = false, ease }) => {
	const width = 640;
	const height = 112;
	return (
		<MaskedImage
			src={mode === "dark" ? logo_dark : logo_light}
			alt=""
			w={width * scale}
			h={height * scale}
			isTrigger={isTrigger}
      ease={ease}
		/>
	);
};
