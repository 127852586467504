import styled from "@emotion/styled";
import { HeroContainer } from "../../components/container/HeroContainer";
import { Flex } from "@mantine/core";
import other from "../../designTokens/other";
import { LogoInteractiveExpandGrid } from "../../components/symbol/LogoInteractiveExpandGrid";
import { Connector } from "../../components/symbol/Connector";
import colors from "../../designTokens/colors";
import { em } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useViewPortLoaded } from "../../hook/useViewPortLoaded";

export const TopHeroSection = ({
	accentColor = colors.blue[3],
	isTrigger,
	unmounted,
	currentSection,
	nextSection,
}) => {
	// Logo Setting
	const logoScale = {
		mb: 0.4,
		pc: 1,
	};
	const { width, isLoaded } = useViewPortLoaded();
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	// Connector Setting
	// Connector Array 8 X 23
	// Row should be even.
	// Col shuold be always 8.
	const connectorArray = [];
	const row = 20;
	const rowPivot = (row - 1) / 2;
	const col = 12;
	const colPivot = (col - 1) / 2;
	// Row
	for (let i = 0; i < row; i++) {
		// Col
		for (let j = 0; j < col; j++) {
			let connector = { zone: 5, row: i, col: j };

			// Zone1 - Edge of Area
			if (i === 0 || i === row - 1 || j === 0 || j === col - 1) {
				connector.zone = 1;
			}
			// Zone2
			if (0 < j && j < col - 1 && (i === 1 || i === row - 2)) {
				connector.zone = 2;
			}
			if (
				Math.abs(colPivot - j) === 2.5 &&
				Math.abs(rowPivot - i) === colPivot
			) {
				connector.zone = 2;
			}
			// Zone3
			if (1 < j && j < col - 2 && (i === 2 || i === row - 3)) {
				connector.zone = 3;
			}
			if (Math.abs(colPivot - j) === 2.5 && Math.abs(rowPivot - i) === 2.5) {
				connector.zone = 3;
			}
			// Zone4
			if (1 < j && j < col - 2 && (i === 3 || i === row - 4)) {
				connector.zone = 4;
			}
			if (Math.abs(colPivot - j) === 2.5 && Math.abs(rowPivot - i) === 1.5) {
				connector.zone = 4;
			}
			// Zone5
			if (1 < j && j < col - 2 && (i === 4 || i === row - 5)) {
				connector.zone = 5;
			}
			if (Math.abs(colPivot - j) === 2.5 && Math.abs(rowPivot - i) === 0.5) {
				connector.zone = 5;
			}
			// Zone6
			if (
				(i === rowPivot - 0.5 || i === rowPivot + 0.5) &&
				1 < j &&
				j < col - 2
			) {
				connector.zone = 6;
			}
			connectorArray.push(connector);
		}
	}

	const Dot = styled.div`
		position: absolute;
		z-index: 0;
		width: 2px;
		height: 2px;
		background-color: rgba(96, 128, 144, 0.4);
	`;

	return (
		<HeroContainer
			isTrigger={isTrigger}
			unmounted={unmounted}
			nextSection={nextSection}
		>
			<Flex
				direction={"row"}
				wrap={"wrap"}
				w={other.size.xl}
				h={"100%"}
				align={"center"}
				style={{
					position: "absolute",
					left: `calc((100% - ${other.size.xxl})/2)`,
				}}
			>
				{connectorArray.map((c, i) => {
					let startDelay =
						Math.floor(Math.random() * Math.random() * Math.random() * 240000) +
						Math.random() * 8000;
					return (
						<Flex
							align={"center"}
							justify={"center"}
							style={{
								position: "relative",
								opacity: isTrigger
									? unmounted
										? 0.5
										: 1
									: nextSection < 2
										? 0.5
										: 0,
							}}
						>
							<Dot />
							<Flex
								style={{
									zIndex: 9,
									// marginLeft: c.row % 2 === 0 && c.col === 0 ? "-6px" : "0px",
								}}
							>
								<Connector
									startDelayUnit={startDelay}
									id={i}
									zone={c.zone}
									accentColor={"#00ddff"}
									isStop={!isTrigger}
									row={c.row}
									currentSection={currentSection}
								/>
							</Flex>
						</Flex>
					);
				})}
			</Flex>
			{isLoaded && (
				<LogoInteractiveExpandGrid
					isExpend={unmounted}
					currentSection={currentSection}
					nextSection={nextSection}
					scale={
						unmounted
							? isMobile
								? (width/2)/(16)
								: 24
							: isMobile
								? logoScale.mb
								: logoScale.pc
					}
				/>
			)}
		</HeroContainer>
	);
};
