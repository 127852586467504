// shinhan
import st_1_1_light from "../assets/stakeholder_logo/light/st_1_1.svg";
// bass
import st_1_2_light from "../assets/stakeholder_logo/light/st_1_2.svg";
// DSasset
import st_1_3_light from "../assets/stakeholder_logo/light/st_1_3.svg";
// Aton
import st_1_4_light from "../assets/stakeholder_logo/light/st_1_4.svg";
// KDB
import st_1_5_light from "../assets/stakeholder_logo/light/st_1_5.svg";
//HB
import st_2_1_light from "../assets/stakeholder_logo/light/st_2_1.svg";
//Dunamu
import st_2_2_light from "../assets/stakeholder_logo/light/st_2_2.svg";
// KB investment
import st_2_3_light from "../assets/stakeholder_logo/light/st_2_3.svg";
// Hwanhwa
import st_2_4_light from "../assets/stakeholder_logo/light/st_2_4.svg";
// Murex Patneers
import st_3_1_light from "../assets/stakeholder_logo/light/st_3_1.svg";
// The seed Group
import st_3_2_light from "../assets/stakeholder_logo/light/st_3_2.svg";
// TYEL
import st_3_3_light from "../assets/stakeholder_logo/light/st_3_3.svg";
// Simone
import st_3_4_light from "../assets/stakeholder_logo/light/st_3_4.svg";
// Mirae Asset
import st_3_5_light from "../assets/stakeholder_logo/light/st_3_5.svg";

import st_1_1_dark from "../assets/stakeholder_logo/dark/st_1_1.svg";
import st_1_2_dark from "../assets/stakeholder_logo/dark/st_1_2.svg";
import st_1_3_dark from "../assets/stakeholder_logo/dark/st_1_3.svg";
import st_1_4_dark from "../assets/stakeholder_logo/dark/st_1_4.svg";
import st_1_5_dark from "../assets/stakeholder_logo/dark/st_1_5.svg";
import st_2_1_dark from "../assets/stakeholder_logo/dark/st_2_1.svg";
import st_2_2_dark from "../assets/stakeholder_logo/dark/st_2_2.svg";
import st_2_3_dark from "../assets/stakeholder_logo/dark/st_2_3.svg";
import st_2_4_dark from "../assets/stakeholder_logo/dark/st_2_4.svg";
import st_3_1_dark from "../assets/stakeholder_logo/dark/st_3_1.svg";
import st_3_2_dark from "../assets/stakeholder_logo/dark/st_3_2.svg";
import st_3_3_dark from "../assets/stakeholder_logo/dark/st_3_3.svg";
import st_3_4_dark from "../assets/stakeholder_logo/dark/st_3_4.svg";
import st_3_5_dark from "../assets/stakeholder_logo/dark/st_3_5.svg";

export const light_logo_list = [
	[st_1_1_light, st_1_2_light, st_1_3_light, st_1_4_light, st_1_5_light],
	[st_2_1_light, st_2_2_light, st_2_3_light, st_2_4_light],
	[st_3_1_light, st_3_2_light, st_3_3_light, st_3_4_light, st_3_5_light],
];

export const dark_logo_list = [
	[st_1_1_dark, st_1_2_dark, st_1_3_dark, st_1_4_dark, st_1_5_dark],
	[st_2_1_dark, st_2_2_dark, st_2_3_dark, st_2_4_dark],
	[st_3_1_dark, st_3_2_dark, st_3_3_dark, st_3_4_dark, st_3_5_dark],
];

export const light_logo_mobile_list = [
	[st_1_1_light, st_1_4_light, st_1_3_light],
	[st_1_2_light, st_3_2_light, st_3_3_light],
	[st_3_4_light, st_3_5_light],
	[st_2_1_light, st_3_1_light],
	[st_2_2_light, st_1_5_light],
	[st_2_4_light, st_2_3_light],
];

export const dark_logo_mobile_list = [
	[st_1_1_dark, st_1_4_dark, st_1_3_dark],
	[st_1_2_dark, st_3_2_dark, st_3_3_dark],
	[st_3_4_dark, st_3_5_dark],
	[st_2_1_dark, st_3_1_dark],
	[st_2_2_dark, st_1_5_dark],
	[st_2_4_dark, st_2_3_dark],
];