import styled from "styled-components";
import { Flex } from "@mantine/core";
import { DivisionContainer } from "../../components/container/DivisionContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { Title, em } from "@mantine/core";
import { Image } from "@mantine/core";
import { light_logo_list, dark_logo_list, light_logo_mobile_list, dark_logo_mobile_list } from "../../data/stakeholderList";
import { useMediaQuery } from "@mantine/hooks";

const LogoContaier = styled(Flex)`
	width: 1200px;
	flex-direction: column;
	flex-wrap: wrap;
	border-radius: 24px;
	padding: 24px;
	@media only screen and (max-width: 750px) {
		border-radius: 16px;
		width: 100%;
		padding: 20px 8px;
	}
`;

export const StakeHolderSection = ({ theme = "light" }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	return (
		<Flex direction={"column"} align={"center"} w={'100%'}>
			<ContentContainer>
				<Flex direction={"column"} align={"center"} w={"100%"} gap={isMobile?24:48} p={isMobile?24:0}>
					<Title
						order={isMobile ? 4 : 2}
						size={isMobile ? 22 : 36}
						ta={"center"}
						lh={isMobile ? "36px" : "64px"}
						c={theme === "light" ? "black" : "white"}
					>
						금융시장의 변화를 함께 만드는
						<br />
						어니스트AI의 주주를 소개합니다
					</Title>
					{!isMobile && (
						<LogoContaier gap={86} bg={theme === "light" ? "white" : "#020616"}>
							{theme === "light" ? (
								<>
									<Flex w={"100%"}>
										{light_logo_list[0].map((img, i) => (
											<Image w={230} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{light_logo_list[1].map((img, i) => (
											<Image w={288} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{light_logo_list[2].map((img, i) => (
											<Image w={230} h={"auto"} src={img} key={i} />
										))}
									</Flex>
								</>
							) : (
								<>
									<Flex w={"100%"}>
										{dark_logo_list[0].map((img, i) => (
											<Image w={230} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{dark_logo_list[1].map((img, i) => (
											<Image w={288} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{dark_logo_list[2].map((img, i) => (
											<Image w={230} h={"auto"} src={img} key={i} />
										))}
									</Flex>
								</>
							)}
						</LogoContaier>
					)}
					{isMobile && (
						<LogoContaier gap={16} bg={theme === "light" ? "white" : "#020616"}>
							{theme === "light" ? (
								<>
									<Flex w={"100%"}>
										{light_logo_mobile_list[0].map((img, i) => (
											<Image w={'33%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{light_logo_mobile_list[1].map((img, i) => (
											<Image w={'33%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{light_logo_mobile_list[2].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{light_logo_mobile_list[3].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{light_logo_mobile_list[4].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{light_logo_mobile_list[5].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
								</>
							) : (
								<>
									<Flex w={"100%"}>
										{dark_logo_mobile_list[0].map((img, i) => (
											<Image w={'33%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{dark_logo_mobile_list[1].map((img, i) => (
											<Image w={'33%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{dark_logo_mobile_list[2].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{dark_logo_mobile_list[3].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{dark_logo_mobile_list[4].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
									<Flex w={"100%"}>
										{dark_logo_mobile_list[5].map((img, i) => (
											<Image w={'50%'} h={"auto"} src={img} key={i} />
										))}
									</Flex>
								</>
							)}
						</LogoContaier>
					)}
				</Flex>
			</ContentContainer>
		</Flex>
	);
};
