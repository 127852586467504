import handleViewport from "react-in-viewport";
import { Container } from "@mantine/core";
import { Flex } from "@mantine/core";

const Wrapper = ({
	inViewport,
	forwardedRef,
	children,
	style,
	className,
	onEnterViewport,
	onLeaveViewport,
}) => (
	<Flex className={className} direction={'column'} style={{...style}} w={"100%"} ref={forwardedRef}>
		{children}
	</Flex>
);

const Block = handleViewport(Wrapper);

const ViewportWrapper = ({
	children,
	onEnterViewport,
	onLeaveViewport,
	style,
	className,
	forwardedRef,
}) => {
	return (
		<Block
			forwardedRef={forwardedRef}
			className={className}
			onEnterViewport={onEnterViewport}
			onLeaveViewport={onLeaveViewport}
			style={style}
		>
			{children}
		</Block>
	);
};

export default ViewportWrapper;
