import * as d3 from "d3";
import { useEffect, useState, useRef } from "react";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { FadeIn } from "../../components/triggerWrapper/FadeIn";
import { Image } from "@mantine/core";
import { Flex, em } from "@mantine/core";
import { Title } from "@mantine/core";
import styled from "styled-components";
import { PathUnitMultiple } from "../../components/logoType/unit/PathUnitMultiple";
import { linkList } from "../../data/linkPathData";
import colors from "../../designTokens/colors";
import { useInterval } from "@mantine/hooks";
import { useMediaQuery } from "@mantine/hooks";

const duration = "0.2s";

const NeuralLinkBG = styled(Flex)`
	-webkit-mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 15%,
		rgba(0, 0, 0, 1) 85%,
		rgba(0, 0, 0, 0) 100%
	);
	mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 15%,
		rgba(0, 0, 0, 1) 85%,
		rgba(0, 0, 0, 0) 100%
	);
	transition: all ${duration} ease-out ${(props) => props.delay};
`;

const NeuralLinkContainer = styled(Flex)`
	transform: scaleY(0.82);
`;

const AITextContainer = styled(Flex)`
	position: absolute;
	left: 120px;
	bottom: 96px;
	z-index: 999;
	@media only screen and (max-width: 750px) {
		width: calc(100% - 48px);
		position: fixed;
		left: 24px;
		top: 36px;
	}
`;

const colorList = [
	colors.blue[4],
	colors.blue[4],
	colors.blue[6],
	colors.blue[6],
	"#00AAFF",
	"#00AAFF",
];

export const AIInteractiveText = ({
	currentSection,
	nextSection,
	isTrigger,
	transitionDelay,
	timingFunction = "ease",
	type,
}) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const accent1 = "#ffffff";
	const accent2 = "#ffffff";

	const delayGap = 0;
	const delayUnit = 600;
	const duration = 200;
	const offset = 6000;

	const fontSize = 124;
	const fontSizeMobile = 56;
	const fontWieght = 500;

	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const text0 = "|";
	const text2 = "Alternative";
	const text1 = "Artificial";
	const text4 = "Investment";
	const text3 = "Intelligence";

	const [word, setWord] = useState(text0);
	const [word2, setWord2] = useState(text0);

	const [seconds, setSeconds] = useState(0);
	const interval = useInterval(() => setSeconds((s) => s + 1), 1000);

	useEffect(() => {
		if (isTrigger) {
			interval.start();
		}

		return interval.stop;
	}, [isTrigger]);

	useEffect(() => {
		if (seconds % 4 == 2 && isLoaded && isTrigger) {
			setWord(text2);
			setWord2(text4);
		} else if (seconds % 4 == 0 && isLoaded && isTrigger) {
			setWord(text1);
			setWord2(text3);
		}
	}, [seconds]);

	useEffect(() => {
		let svg;
		let g1;
		let g2;
		if (!isLoaded && isTrigger) {
			svg = d3
				.select(svgRef.current)
				.attr("width", isMobile ? 360 : 720)
				.attr("height", isMobile ? 156 : 320);
			g1 = svg
				.append("g")
				.attr("class", "textContainer1")
				.attr("transform", `translate(0,${isMobile ? 80 : 172})`);

			g2 = svg
				.append("g")
				.attr("class", "textContainer2")
				.attr("transform", `translate(0,${isMobile ? 144 : 278})`);

			setIsLoaded(true);
			setWord(text1);
			setWord2(text3);
			updateText(1);
			updateText(2);
		}
	}, [isTrigger, isLoaded]);

	useEffect(() => {
		if (isLoaded) {
			updateText(1);
		}
	}, [word]);

	useEffect(() => {
		if (isLoaded) {
			updateText(2);
		}
	}, [word2]);

	const updateText = (id) => {
		// Create a hidden text element for measuring character widths
		const hiddenText = d3
			.select(`.textContainer${id}`)
			.append("text")
			.attr("x", -offset)
			.attr("y", -offset)
			.attr("font-size", isMobile ? fontSizeMobile : fontSize)
			.attr("font-family", "SF Pro Display")
			.attr("font-weight", fontWieght);

		// Calculate the width of each character
		let characters;
		if (id === 1) {
			characters = word.split("");
		} else {
			characters = word2.split("");
		}
		const characterWidths = characters.map((char) => {
			hiddenText.text(char);
			return hiddenText.node().getComputedTextLength();
		});

		// Remove the hidden text element
		hiddenText.remove();

		const t = d3
			.select(`.textContainer${id}`)
			.transition()
			.duration(duration)
			.ease(d3.easeExpInOut);

		const textUpdate = d3
			.select(`.textContainer${id}`)
			.selectAll("text")
			.data(id === 1 ? word : word2, (d) => d)
			.attr("fill", accent1)
			.attr("y", 0)
			.call((update) =>
				update
					.transition(t)
					.delay(() => (Math.random() * delayUnit) / 2 + delayGap)
					.attr("x", (d, i) => d3.sum(characterWidths.slice(0, i)))
			);

		const textEnter = textUpdate
			.enter()
			.append("text")
			.attr("fill", accent1)
			.attr("font-size", isMobile ? fontSizeMobile : fontSize)
			.attr("font-family", "SF Pro Display")
			.attr("font-weight", fontWieght)
			.attr("x", -900)
			.attr("y", 0)
			.text((d) => d)
			.attr("opacity", 0)
			.call((enter) =>
				enter
					.transition(t)
					.delay(() => (Math.random() * delayUnit) / 2 + delayGap)
					.attr("y", 0)
					.attr("x", (d, i) => d3.sum(characterWidths.slice(0, i)))
					.attr("opacity", 1)
					.transition()
					.duration(16)
					.attr("opacity", 0.5)
					.transition()
					.delay(32)
					.duration(16)
					.attr("opacity", 1)
			);

		const textExit = textUpdate
			.exit()
			.attr("fill", accent1)
			.call((exit) =>
				exit
					.transition(t)
					.delay(() => (Math.random() * delayUnit) / 2)
					.attr("opacity", 0)
					// .attr("y", 400)
					.remove()
			);
	};

	const checkTrigger = () => {
		return (
			(nextSection === 2 || currentSection === 2) &&
			nextSection !== 1 &&
			nextSection !== 3
		);
	};
	return (
		<FullPageContainer style={{ position: "fixed", top: 0, left: 0 }}>
			<ContentContainer h={"100%"} style={{ position: "relative" }}>
				<AITextContainer direction={"column"}>
					<FadeIn isTrigger={checkTrigger()} delay={transitionDelay}>
						<svg ref={svgRef} />
					</FadeIn>
					<FadeIn isTrigger={checkTrigger()} delay={transitionDelay}>
						<Title order={isMobile ? 5 : 3} c={"white"} w={"100%"}>
							철학과 원칙에 기반한 기술을 통해 금융산업을 혁신합니다
						</Title>
					</FadeIn>
				</AITextContainer>
				<FadeIn isTrigger={checkTrigger()} delay={transitionDelay}>
					{!isMobile && (
						<NeuralLinkBG
							style={{
								position: "absolute",
								top: isMobile ? 294 : 150,
								right: isMobile ? -832 : -46,
								zIndex: -1,
							}}
						>
							<NeuralLinkContainer>
								<PathUnitMultiple
									width={1920}
									height={826}
									dataList={linkList}
									scale={1}
									strokeColor={"#0022ff"}
									strokeWidth={1}
									colorList={colorList}
									delay={100}
									startDelay={400}
									isTrigger={checkTrigger()}
								/>
							</NeuralLinkContainer>
						</NeuralLinkBG>
					)}
				</FadeIn>
			</ContentContainer>
		</FullPageContainer>
	);
};
