import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";
const colors = require("../../designTokens/colors");

export const BlinkerCustom = ({
	scale = 1,
	id = 1,
	startDelay,
	accentColor,
	unit1,
	unit2,
}) => {
	// Symbol Spec
	const width = unit1 + unit2;
	const height = unit1 + unit2;
	const accentColor1 = colors.blue[1];
	const accentColor2 = accentColor;
	const timeUnit = 160;
	const duration = timeUnit * 1;
	const delay = timeUnit * 1;
	const opacity = {
		show: 1,
		hide: 0,
	};

	const squareList = [
		// 1st Square Set
		{ 
			x: 0, 
			y: unit2 * 1,
			timeSlop: 0,
			unit: unit1
		},
		{ 
			x: unit1 * 1,
			y: 0,
			timeSlop: 1,
			unit: unit2 },
	];

	const ease = d3.easeBounceInOut;

	// Init SVG
	let svgRef = useRef(null);
	let transitionRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		// initialize SVG @ 1st Load
		if (!isLoaded) {
			const svg = d3.select(svgRef.current);
			const g = svg
				.attr("class", `symbolSet_${id}`)
				.attr("width", width * scale)
				.attr("height", height * scale)
				.append("g");

			const symbolSet = g
				.selectAll("rect")
				.data(squareList)
				.enter()
				.append("rect")
				.attr("x", (d) => d.x * scale)
				.attr("y", (d) => d.y * scale)
				.attr("width", (d) => d.unit * scale)
				.attr("height", (d) => d.unit * scale)
				.attr("fill", (d, i) => {
					if (i % 2 === 0) return accentColor2;
					else return accentColor1;
				})
				.attr("opacity", 0.1);
		}
		setIsLoaded(true);
		transitionRef.current = blink;
		blink();
	}, [isLoaded]);

	useEffect(() => {
		return () => {
			exit();
		};
	}, []);

	function blink() {
		if (d3.select(`.symbolSet_${id}`).node()) {
			d3.select(`.symbolSet_${id}`)
				.selectAll("rect")
				.attr("opacity", opacity.hide)
				// Appear
				.transition()
				.ease(ease)
				.delay((d) => d.timeSlop * delay + startDelay)
				.duration(duration)
				.attr("opacity", opacity.show)
				// Hide
				.transition()
				.ease(ease)
				.delay(delay)
				.duration(duration)
				.attr("opacity", opacity.hide)
				// Wiating Next Appear
				.transition()
				.ease(ease)
				.delay((d) => (24 - d.timeSlop) * delay)
				.duration(0)
				.attr("opacity", opacity.hide)
				.on("end", blink);
		}
	}

	function exit() {
		console.log("exit");
		d3.select(`.symbolSet_${id}`)
			.selectAll("rect")
			.transition()
			.attr("opacity", 0);
		d3.select(`.symbolSet_${id}`).remove();
	}

	return <svg ref={svgRef} />;
};
