import { useState, useEffect } from "react";
import { FullPageContainer } from "../../components/layout/container/FullPageContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { Title, Flex, em } from "@mantine/core";
import { Group, Chip } from "@mantine/core";
import { HistoryList } from "../../components/list/HistoryList";
import { companyHistoryList } from "../../data/companyHistory";
import styled from "styled-components";
import { useViewportSize } from "@mantine/hooks";
import { FadeInToTop } from "../../components/triggerWrapper/FadeInToTop";
import { FadeIn } from "../../components/triggerWrapper/FadeIn";
import { useMediaQuery } from "@mantine/hooks";

const Wrapper = styled(Flex)`
	margin: 24px 0px;
	position: relative;
	-webkit-mask-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 10%
	);
	mask-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 10%
	);
`;

const HistoryContainer = styled(Flex)`
	position: relative;
	overflow: hidden;
`;

const TimeSlotWrapper = styled(Flex)`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: ${(props) => `${props.top}px`};
	transition: all 0.4s ease-in-out;
`;

const TimeSlot = styled(Flex)`
	position: absolute;
	top: ${(props) => props.top};
	left: 0;
	opacity: ${(props) => (props.isTrigger ? 1 : 0)};
	transition: all 0.3s ease-in ${(props) => props.delay};
`;

const Timeline = styled(Flex)`
	position: absolute;
	left: calc(50% - 0.5px);
	bottom: 0;
	width: 1px;
	height: 100%;
	z-index: -1;
`;

export const CompanyHistorySection = ({ isTrigger, ref }) => {
	const yearSelect = [
		{
			label: "2015-2016",
			topIndex: "21",
		},
		{
			label: "2017-2018",
			topIndex: "15",
		},
		{
			label: "2019-2022",
			topIndex: "11",
		},
		{
			label: "2023-2024",
			topIndex: "0",
		},
	];

	const [year, setYear] = useState(yearSelect[3].topIndex);
	const [position, setPosition] = useState(0);
	const { height } = useViewportSize();
	const amount = "120px";

	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const itemGap = 72;
	const itemGapMobile = 52;

	const [gap, setGap] = useState(0);

	const onChangeTimeSlot = (value) => {
		setYear(value);
		let gap = isMobile ? itemGapMobile : itemGap;
		let top = parseInt(value) * -gap;
		setPosition(top);
	};

	useEffect(() => {
		if (isMobile) {
			setGap(itemGapMobile);
		} else {
			setGap(itemGap);
		}
	}, [isMobile]);

	return (
		<FullPageContainer>
			<ContentContainer h={"100%"}>
				<Flex
					direction={"column"}
					align={"center"}
					w={"100%"}
					gap={isMobile ? 16 : 48}
				>
					<FadeIn isTrigger={isTrigger} amount={amount} delay={"0.2s"}>
						<Title
							style={{ whiteSpace: "break-spaces" }}
							ref={ref}
							c={"white"}
							ta={"center"}
							order={isMobile ? 3 : 1}
						>
							{isMobile
								? `어니스트AI가 걸어온\n혁신의 역사`
								: `어니스트AI가 걸어온 혁신의 역사`}
						</Title>
					</FadeIn>
					<FadeIn
						style={{ width: "100%" }}
						isTrigger={isTrigger}
						amount={amount}
						delay={"0.4s"}
					>
						<Chip.Group
							multiple={false}
							value={year}
							onChange={onChangeTimeSlot}
							justify="center"
							w={"100%"}
						>
							<Flex
								wrap={"wrap"}
								justify={"center"}
								w={"100%"}
								gap={isMobile ? 4 : 16}
							>
								{yearSelect.map((item, i) => (
									<Chip
										icon={null}
										test={"text"}
										key={i}
										size={isMobile ? "sm" : "md"}
										color={"white"}
										variant={"outline-white"}
										value={item.topIndex}
										checked={item.topIndex === year}
									>
										{item.label}
									</Chip>
								))}
							</Flex>
						</Chip.Group>
					</FadeIn>
					<FadeIn
						style={{ width: "100%" }}
						isTrigger={isTrigger}
						amount={amount}
						delay={"0.6s"}
					>
						<Wrapper direction={"column"} align={"center"}>
							<Timeline bg={"gray.7"} />
							<HistoryContainer
								direction={"column"}
								align={"center"}
								w={isMobile ? "100%" : "568px"}
								h={isMobile ? height - 120 : height - 360}
							>
								<TimeSlotWrapper top={position}>
									{companyHistoryList.map((item, i) => (
										<TimeSlot
											isTrigger={isTrigger}
											w={"100%"}
											top={
												isTrigger
													? `${i * gap}px`
													: `${(i - 1) * gap}px`
											}
											delay={`${i * 0.03}s`}
											justify={i % 2 === 0 ? "start" : "end"}
										>
											<HistoryList
												title={item.title}
												date={item.date}
												position={i % 2 === 0 ? "left" : "position"}
											/>
										</TimeSlot>
									))}
								</TimeSlotWrapper>
							</HistoryContainer>
						</Wrapper>
					</FadeIn>
				</Flex>
			</ContentContainer>
		</FullPageContainer>
	);
};
