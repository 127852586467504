import { useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import other from "../designTokens/other";

export const useBreakPoint = () => {
	const { width } = useViewportSize();
	const [breakPoint, setBreakpoint] = useState(getBreakPoint(width));
	const [isLoaded, setIsloaded] = useState(false);
	useEffect(() => {
		if(width!==0){
			let bp = getBreakPoint(width);
			setBreakpoint(bp);
			setIsloaded(true);
		}
	}, [width]);

	return { breakPoint, getResponsiveValue, isLoaded };

	function getBreakPoint(width) {
		const xs = remToInt(other.size.xs);
		const sm = remToInt(other.size.sm);
		const md = remToInt(other.size.md);
		const lg = remToInt(other.size.lg);

		if (width <= xs) {
			return "xs";
		} else if (xs < width && width <= sm) {
			return "sm";
		} else if (sm < width && width <= md) {
			return "md";
		} else if (md < width && width <= lg) {
			return "lg";
		} else if (lg < width) {
			return "xl";
		}
	}

	function remToInt(remString) {
		// Extract the numeric part of the string using regular expression
		const numericPart = remString.match(/^\d+/);

		// Convert the extracted numeric part to an integer
		if (numericPart) {
			return parseInt(numericPart[0]) * 16;
		} else {
			// Return NaN if the string doesn't match the expected format
			return NaN;
		}
	}

	function getResponsiveValue(value, bp) {
		let returnValue;
		if (value) {
			if (bp === "xs") returnValue = value.xs;
			else if (bp === "sm") returnValue = value.sm;
			else if (bp === "md") returnValue = value.md;
			else if (bp === "lg") returnValue = value.lg;
			else if (bp === "xl") returnValue = value.xl;

			return returnValue;
		}
	}
};
