import styled from "@emotion/styled";
import { Container } from "@mantine/core";
import { FullPageContainer } from "../layout/container/FullPageContainer";

const GradientContainer = styled(Container)`
	background: radial-gradient(
		170.51% 70.71% at 50% 50%,
		#044fff 0%,
		#000c78 80%
	);
	background-size: 180% 180%;
	transition: all 0.3s ease-in-out;
	animation: gradient-animation 4s ease infinite;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	::before {
		width: 100%;
		height: 100%;
		position: absolute;
		content: "";
		background-image: radial-gradient(
			rgba(0, 0, 0, 0.86),
			rgba(0, 0, 0, 0.90),
			rgba(0, 0, 0, 0.94)
		);
		/* z-index: 9; */
	}

	@keyframes gradient-animation {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const GrayContainer = styled(Container)`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.nextSection < 3 ? "#F6FAFC00" : 5 < props.nextSection?"#03030400":"#F6FAFCff"};
	transition: all 0.3s ease-in-out;
	transition: all 0.2s ease-in;
`;

export const HeroContainer = ({
	children,
	isTrigger,
	unmounted,
	nextSection,
}) => {
	return (
		<FullPageContainer style={{ position: "fixed", top: 0, left: 0 }}>
			<GradientContainer nextSection={nextSection} w={"100%"} h={"100%"}>
				<GrayContainer
					isTrigger={isTrigger}
					nextSection={nextSection}
					unmounted={unmounted}
				>
					{children}
				</GrayContainer>
			</GradientContainer>
		</FullPageContainer>
	);
};
