import { useEffect, useRef } from "react";
import { Flex } from "@mantine/core";
import { Image } from "@mantine/core";
import styled from "styled-components";
import { Title, Text, em } from "@mantine/core";
import img_app_bar from "../../../assets/screen/honestFundInvest/Top Bar.jpg";
import img_card1 from "../../../assets/screen/honestFundInvest/card1.png";
import img_card2 from "../../../assets/screen/honestFundInvest/card2.png";
import img_popular_product from "../../../assets/screen/honestFundInvest/popular_product.png";
import img_bottom_nav from "../../../assets/screen/honestFundInvest/BottomNav1.jpg";
import { FadeIn } from "../../../components/triggerWrapper/FadeIn";
import CountUp from "react-countup";
import { useMediaQuery } from "@mantine/hooks";

const Container = styled(Flex)`
	border-radius: 8px;
	height: 100%;
	overflow: hidden;
	background: linear-gradient(#ffffff, #f0f0f0);
	justify-content: space-between;
	position: relative;
`;

export const ScreenHonest = ({ isTrigger }) => {
	let startFunction;

	useEffect(() => {
		if (isTrigger) {
			startFunction();
		}
	}, [isTrigger]);
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	return (
		<Container direction={"column"} w={390}>
			<Image
				w={"calc(100% - 16px)"}
				ml={"auto"}
				mr={"auto"}
				h={"auto"}
				mt={4}
				src={img_app_bar}
			/>
			{/* Expected Return */}
			<FadeIn isTrigger={isTrigger}>
				<Flex direction={"column"} p={24}>
					<Title c={"gray.6"} order={5}>
						김정직님의 12월 예상 수익 
					</Title>
					<Title c={"gray.9"} order={1}>
						<CountUp
							start={0}
							end={1230580}
							duration={1}
							separator=","
							decimals={0}
							decimal=","
							suffix="원"
						>
							{({ countUpRef, start }) => {
								startFunction = start;
								return (
									<div>
										<span ref={countUpRef} />
									</div>
								);
							}}
						</CountUp>
					</Title>
					<Title c={"blue.5"} order={5}>
						▲ 지난달 실수익보다 24% 증가했어요!
					</Title>
				</Flex>
			</FadeIn>
			{/* Card */}
			<FadeIn isTrigger={isTrigger} delay={"0.3s"}>
				<Flex gap={11} p={12}>
					<Image src={img_card1} w={318} h={"auto"} />
					<Image src={img_card2} w={318} h={"auto"} />
				</Flex>
			</FadeIn>
			{/* Popular Product */}
			<FadeIn isTrigger={isTrigger} delay={"0.5s"}>
				<Flex p={24} style={{ borderRadius: 8, overflow: "hidden" }}>
					<Image
						src={img_popular_product}
						w={isMobile ? "100%" : 318}
						h={"auto"}
					/>
				</Flex>
			</FadeIn>
			<Image
				style={{ position: "absolute", left: 0, bottom: 0 }}
				src={img_bottom_nav}
				w={"100%"}
				h={"auto"}
			/>
		</Container>
	);
};
