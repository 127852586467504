import logo_chosun from "../assets/media_logo/ic_media_chosun.svg";
import logo_edaily from "../assets/media_logo/ic_media_edaily.svg";
import logo_electronic from "../assets/media_logo/ic_media_electronic.svg";
import logo_finance from "../assets/media_logo/ic_media_finace.svg";
import logo_herald from "../assets/media_logo/ic_media_herald.svg";
import logo_hk from "../assets/media_logo/ic_media_hk.svg";
import logo_mk from "../assets/media_logo/ic_media_mk.svg";
import logo_seoul from "../assets/media_logo/ic_media_seoul.svg";


export const mediaList = [
	{
		category: "Company",
		media: logo_hk,
		date: "2024.01.11",
		title: "어니스트펀드, 진원그룹서 50억원 투자 유치…'AI 솔루션' 기술 인정",
		link: "https://n.news.naver.com/mnews/article/015/0004935611?sid=101",
	},
	{
		category: "Company",
		media: logo_electronic,
		date: "2023.09.19",
		title: "어니스트펀드, AI 여신 종합 솔루션 '렌딩 인텔리전스' B2B 상용화",
		link: "https://n.news.naver.com/mnews/article/030/0003137727?sid=101",
	},
	{
		category: "Company",
		media: logo_finance,
		date: "2023.03.30",
		title: '"온투업 기관투자 활성화 대비"...어니스트펀드-BNK저축은행 맞손',
		link: "https://n.news.naver.com/mnews/article/014/0004989234?sid=101",
	},
	{
		category: "Tech",
		media: logo_herald,
		date: "2024.03.28",
		title: "어니스트펀드, SK그룹사에 ‘세입자 신용평가 모델’ 공급한다",
		link: "https://n.news.naver.com/mnews/article/016/0002287312?sid=101",
	},
	{
		category: "Tech",
		media: logo_edaily,
		date: "2023.02.01",
		title: "어니스트펀드, AI기반 ‘데이터 연합학습 솔루션’ 상용화",
		link: "https://n.news.naver.com/mnews/article/018/0005415974?sid=101",
	},
	{
		category: "Tech",
		media: logo_seoul,
		date: "2022.08.18",
		title:
			"어니스트펀드, 인공지능 및 빅데이터 기반 신용평가 등 특허 출원",
		link: "https://www.sedaily.com/NewsView/269RP4UVC2",
	},
	{
		category: "Interview",
		media: logo_seoul,
		date: "2024.02.02",
		title: '"리스크 20% 줄인 어니스트펀드 신용평가…온투업 B2B 스텝업 기회로"',
		link: "https://n.news.naver.com/mnews/article/011/0004295300?sid=101",
	},
	{
		category: "Interview",
		media: logo_chosun,
		date: "2023.12.04",
		title:
			"어니스트펀드 신윤제 CDO(최고데이터책임자)\n“AI가 300개 항목으로 돈 잘 갚을 고객 찾죠",
		link: "https://economychosun.com/site/data/html_dir/2023/12/04/2023120400027.html",
	},
	{
		category: "Interview",
		media: logo_finance,
		date: "2023.12.04",
		title: '회·파·복 두려운 금융사에 AI솔루션 공급',
		link: "https://n.news.naver.com/mnews/article/014/0005110035?sid=101",
	},
	{
		category: "Award",
		media: logo_electronic,
		date: "2024.01.11",
		title: "어니스트펀드, 2023 'D-테스트베드' 사업 금융위원장 대상 수상",
		link: "https://n.news.naver.com/mnews/article/030/0003172700?sid=101",
	},
	{
		category: "Award",
		media: logo_mk,
		date: "2017.08.29",
		title: "어니스트펀드, 매경 핀테크 어워드 우수상 수상",
		link: "https://www.mk.co.kr/news/special-edition/7954723",
	},
	{
		category: "Award",
		media: logo_hk,
		date: "2016.09.28",
		title: "어니스트펀드, 한경 핀테크대상 데이터분석·리스크 관리로 최우수상",
		link: "https://www.hankyung.com/news/app/newsview.php?aid=2016092826631&intype=1",
	},
];
