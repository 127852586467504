import * as d3 from "d3";
import { useRef, useEffect, useState } from "react";
const colors = require("../../designTokens/colors");

export const Creator = ({ scale = 1 }) => {
	// Symbol Spec
	const width = 32;
	const height = 96;
	const accentColor1 = colors.blue[5];
	const accentColor2 = colors.blue[1];
	const timeUnit = 120;
	const duration = timeUnit * 1;
	const delay = timeUnit * 1;
	const opacity = {
		show: 1,
		hide: 0.15,
	};

	const squareList = [
		// 1st Square Set
		{ x: 16 * 0, y: 16 * 5, timeSlop: 0 },
		{ x: 16 * 1, y: 16 * 4, timeSlop: 1 },
		// 2st Square Set
		{ x: 16 * 0, y: 16 * 3, timeSlop: 4 },
		{ x: 16 * 1, y: 16 * 2, timeSlop: 5 },
		// 3st Square Set
		{ x: 16 * 0, y: 16 * 1, timeSlop: 8 },
		{ x: 16 * 1, y: 16 * 0, timeSlop: 9 },
	];

	const ease = d3.easeQuadOut;

	// Init SVG
	let svgRef = useRef(null);
	let transitionRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		// initialize SVG @ 1st Load
		if (!isLoaded) {
			const svg = d3.select(svgRef.current);
			const g = svg
				.attr("class", "symbolSet")
				.attr("width", width * scale)
				.attr("height", height * scale)
				.append("g");

			const symbolSet = g
				.selectAll("rect")
				.data(squareList)
				.enter()
				.append("rect")
				.attr("x", (d) => d.x)
				.attr("y", (d) => d.y)
				.attr("width", 16 * scale)
				.attr("height", 16 * scale)
				.attr("fill", (d, i) => {
					if (i % 2 === 0) return accentColor1;
					else return accentColor2;
				})
				.attr("opacity", 0.1);
		}
		setIsLoaded(true);
		transitionRef.current = blink;
		blink();
	}, [isLoaded]);

	useEffect(() => {
		return () => {
			exit();
		};
	}, []);

	function blink() {
		if (d3.select(".symbolSet").node()) {
			d3.select(".symbolSet")
				.selectAll("rect")
				.attr("opacity", opacity.hide)
				// Appear
				.transition()
				.ease(ease)
				.delay((d) => d.timeSlop * delay)
				.duration(duration)
				.attr("opacity", opacity.show)
				// Hide
				.transition()
				.ease(ease)
				.delay(delay)
				.duration(duration)
				.attr("opacity", opacity.hide)
				// Wiating Next Appear
				.transition()
				.ease(ease)
				.delay((d) => (10 - d.timeSlop) * delay)
				.duration(0)
				.attr("opacity", opacity.hide)
				.on("end", blink);
		}
	}

	function exit() {
		console.log("exit");
    d3.select(".symbolSet").selectAll("rect").transition().attr("opacity",0);
		d3.select(".symbolSet").remove();
	}

	return <svg ref={svgRef} />;
};
