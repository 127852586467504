

export const FadeInToRight = ({
	isTrigger,
	duration = "0.3s",
	delay = "0s",
	children,
	timingFunction = "ease-out",
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				opacity: isTrigger ? 1 : 0,
				marginLeft: isTrigger ? 0 : "-96px",
				marginRight: isTrigger ? 0 : "96px",
				transition: `all ${duration} ${timingFunction} ${delay}`,
			}}
		>
			{children}
		</div>
	);
};
