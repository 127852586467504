import { Flex } from "@mantine/core";
import { Text, Title, Badge, UnstyledButton, em } from "@mantine/core";
import { styled } from "styled-components";
import { Image } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import colors from "../../designTokens/colors";
import { useMediaQuery } from "@mantine/hooks";

const Link = styled(UnstyledButton)`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	border: 1px solid ${colors.gray[6]};
	overflow: hidden;
	color: ${colors.blue[3]};
	* {
		opacity: 0.95;
	}
	transition: all 0.3s ease-in-out;
	&:hover {
		border: 1px solid ${colors.gray[0]};
		color: ${colors.gray[0]};
    * {
		opacity: 1;
	}
	}
`;
const Container = styled(Flex)``;

export const CardNews = ({ date, category, title, media, link }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	return (
		<Link component={"a"} target={"_blank"} href={link}>
			<Container direction={"column"} gap={isMobile?0:96}>
				{/* Title */}
				<Flex direction={"column"} p={isMobile?16:24} gap={isMobile?20:48}>
					<Flex justify={"space-between"} align={"center"}>
						<Title order={isMobile?6:5} c={"gray.4"}>
							{date}
						</Title>
						<Badge pt={2} style={{textTransform:'none'}} variant={"outline"} color={"gray.3"} size={isMobile?"md":"lg"}>
							{category}
						</Badge>
					</Flex>
					<Title c={"gray.1"} order={isMobile?5:4} h={isMobile?'auto':74}>
						{title}
					</Title>
				</Flex>
				{/* Media */}
				<Flex
					p={isMobile?16:24}
					pt={16}
					bg={"gray.9"}
					align={"end"}
					justify={"space-between"}
				>
					<Flex h={isMobile?20:48} align={"end"}>
						<Image h={16} w={'auto'} src={media} alt="" />
					</Flex>
					<IconChevronRight size={16} />
				</Flex>
			</Container>
		</Link>
	);
};
