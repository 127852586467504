import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./globalStyle/type.css";
import "./globalStyle/components.css";
import "./globalStyle/color.css";
import '@mantine/notifications/styles.css';

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import { theme } from "./globalStyle/theme.js";
import { ThemeProvider } from "@emotion/react";
import { Notifications } from '@mantine/notifications';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
		<ThemeProvider theme={theme}>
				<Notifications position="top-center" zIndex={99999}/>
				<App />
		</ThemeProvider>
	</MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
