import * as d3 from "d3";
import { useEffect, useState, useRef } from "react";
// import { useInterval } from "react-timing-hooks";
import styled from "styled-components";

const G = styled.g`
	transform-origin: 50% 50%;
`;

export const SymbolWithAI = ({scale = 1}) => {
	let path;
	const duration = 1400;
	const color1 = '#00aaFF';
	const color2 = '#000A67';

	for (let i = 0; i++; i < 3) {
    let colors = [];
		for (let j = 0; j++; j < 17) {
      
      if(i===0){
        if(j===0 || j===16) colors.push(color2);
        else colors.push(color1);
      }
      else if(i===0){
        if(j===4 || j===5) colors.push(color2);
        else colors.push(color1);
      }
      else if(i===0){
        if(j===10 || j===11) colors.push(color2);
        else colors.push(color1);
      }
    }
	}

	// 16 step
	const color = [
		d3.interpolateRgbBasis([
			color2,
			color1,
			color1,
			color1,
			color1,
			color1,
			color1,
			color2,
		]),
		d3.interpolateRgbBasis([
			color1,
			color2,
			color2,
			color1,
			color1,
			color1,
			color1,
			color1,
		]),
		d3.interpolateRgbBasis([
			color1,
			color1,
			color1,
			color2,
			color2,
			color1,
			color1,
			color1,
		]),
		d3.interpolateRgbBasis([
			color1,
			color1,
			color1,
			color1,
			color2,
			color2,
			color1,
			color1,
		]),
		d3.interpolateRgbBasis([
			color1,
			color1,
			color1,
			color1,
			color1,
			color2,
			color2,
			color1,
		]),
	];

	const [cycle, setCycle] = useState(1);

	function useInterval(callback, delay) {
		const savedCallback = useRef();

		// Remember the latest callback.
		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);

		// Set up the interval.
		useEffect(() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => clearInterval(id);
			}
			return () => clearInterval();
		}, [delay]);
	}

	useInterval(() => {
		setCycle((c) => c + 1);
	}, duration);

	useEffect(() => {
		path = d3.select(".symbol_path").remove();

		d3.select(".symbol_ai")
			.selectAll("path")
			.data(quads(samples(path.node(), 1)))
			.enter()
			.append("path")
			.style("fill", function (d) {
				return color[0](d.t);
			})
			.style("stroke", function (d) {
				return color[0](d.t);
			})
			.attr("d", function (d) {
				return lineJoin(d[0], d[1], d[2], d[3], 6);
			});

		return () => {
			d3.select(".symbol_ai").remove();
		}	
	}, []);

	useEffect(() => {
		animateGradient();
	}, [cycle]);

	// Sample the SVG path uniformly with the specified precision.
	function samples(path, precision) {
		let n = path.getTotalLength(),
			t = [0],
			i = 0,
			dt = precision;
		while ((i += dt) < n) t.push(i);
		t.push(n);
		return t.map(function (t) {
			let p = path.getPointAtLength(t),
				a = [p.x, p.y];
			a.t = t / n;
			return a;
		});
	}

	// Compute quads of adjacent points [p0, p1, p2, p3].
	function quads(points) {
		return d3.range(points.length - 1).map(function (i) {
			const a = [points[i - 1], points[i], points[i + 1], points[i + 2]];
			a.t = (points[i].t + points[i + 1].t) / 2;
			return a;
		});
	}

	// Compute stroke outline for segment p12.
	function lineJoin(p0, p1, p2, p3, width) {
		let u12 = perp(p1, p2),
			r = width / 2,
			a = [p1[0] + u12[0] * r, p1[1] + u12[1] * r],
			b = [p2[0] + u12[0] * r, p2[1] + u12[1] * r],
			c = [p2[0] - u12[0] * r, p2[1] - u12[1] * r],
			d = [p1[0] - u12[0] * r, p1[1] - u12[1] * r];

		if (p0) {
			// clip ad and dc using average of u01 and u12
			let u01 = perp(p0, p1),
				e = [p1[0] + u01[0] + u12[0], p1[1] + u01[1] + u12[1]];
			a = lineIntersect(p1, e, a, b);
			d = lineIntersect(p1, e, d, c);
		}

		if (p3) {
			// clip ab and dc using average of u12 and u23
			let u23 = perp(p2, p3),
				e = [p2[0] + u23[0] + u12[0], p2[1] + u23[1] + u12[1]];
			b = lineIntersect(p2, e, a, b);
			c = lineIntersect(p2, e, d, c);
		}

		return "M" + a + "L" + b + " " + c + " " + d + "Z";
	}

	// Compute intersection of two infinite lines ab and cd.
	function lineIntersect(a, b, c, d) {
		let x1 = c[0],
			x3 = a[0],
			x21 = d[0] - x1,
			x43 = b[0] - x3,
			y1 = c[1],
			y3 = a[1],
			y21 = d[1] - y1,
			y43 = b[1] - y3,
			ua = (x43 * (y1 - y3) - y43 * (x1 - x3)) / (y43 * x21 - x43 * y21);
		return [x1 + ua * x21, y1 + ua * y21];
	}

	// Compute unit vector perpendicular to p01.
	function perp(p0, p1) {
		let u01x = p0[1] - p1[1],
			u01y = p1[0] - p0[0],
			u01d = Math.sqrt(u01x * u01x + u01y * u01y);
		return [u01x / u01d, u01y / u01d];
	}

	function animateGradient() {
		console.log('animtaed');
		let index = cycle % color.length;
		d3.select(".symbol_ai")
			.selectAll("path")
			.transition()
			.ease(d3.easeLinear)
			.duration(duration)
			.style("fill", function (d) {
				return color[index](d.t);
			})
			.style("stroke", function (d) {
				return color[index](d.t);
			});
	}

	return (
		<svg
			className="symbol_ai"
			width={768*scale}
			height={768*scale}
			viewBox={`0 0 ${768*scale} ${768*scale}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<G clipPath="url(#clip0_2993_3993)" transform={`scale(${scale})`}>
				<path
					className="symbol_path"
					d={'M273 384H6V762H384V384V6H762V384H273Z'}
					stroke="black"
				/>
			</G>
			<defs>
				<clipPath id="clip0_2993_3993">
					<rect width={768} height={768} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
