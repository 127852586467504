import { BrowserRouter } from "react-router-dom";
import "@mantine/core/styles.css";
import { AppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PageMainFullPageVer2 } from "./page/PageMainFullPageVer2";


function App() {
	const [opened] = useDisclosure();

	return (
		<BrowserRouter>
			<AppShell
				header={{ height: 60 }}
				navbar={{
					width: 300,
					breakpoint: "sm",
					collapsed: { mobile: !opened },
				}}
			>
				<AppShell.Main style={{ padding: 0, margin: 0 }}>
					<PageMainFullPageVer2 />
				</AppShell.Main>
			</AppShell>
		</BrowserRouter>
	);
}

export default App;
