import ic_credit from "../assets/icon/ai/ic_credit.svg";
import ic_AI from "../assets/icon/ai/ic_AI.svg";
import ic_monitoring from "../assets/icon/ai/ic_monitoring.svg";
import ic_AI_white from "../assets/icon/ai/ic_AI_white.svg";
import ic_credit_white from "../assets/icon/ai/ic_credit_white.svg";

export const featureList = [
	{
		title: "더 높은 승인률로 더 낮은 불량률 달성",
		overline: "어니스트AI는 정확합니다",
		exp: `개인신용평가모형에 대한 깊은 도메인 지식, CB 금융데이터 및 비금융데이터에 대한 노하우, 끊임없는 연구개발로 고도화된 ML 알고리즘에 기반한 어니스트AI 모델은 예측의 정확성을 자부합니다.`,
		img: ic_credit,
	},
	{
		title: "최신 데이터 경향을 모델에 최대한 빠르게 반영",
		overline: "어니스트AI는 최신입니다",
		exp: `독자 개발한 MLOps AI 파이프라인을 통해 데이터셋 적재부터 모델 서빙까지 소요되는 시간을 크게 단축시켰으며, 1년에 2번 이상 빠른 주기의 업데이트를 진행하여 모델을 언제나 최신식으로 유지합니다.`,
		img: ic_AI,
	},
	{
		title: "고객, 상품, 전락목표 등에 맞게 최적화",
		overline: "어니스트AI는 맞춤입니다",
		exp: `어니스트AI 파이프라인은 직장인 외 다양한 세그먼트, 일반연체 외 다양한 타겟 등 복합적 모델 요건 구성에 최적화되어 있으며, 이를 통해 금융기관의 상황, 전략목표에 부합하도록 맞춤식으로 모델을 제공합니다.`,
		img: ic_monitoring,
	},
];

export const featureList2 = [
	{
		title: "온라인으로 대출과 투자가 연결되는 대체투자 플랫폼 ",
		overline: "어니스트AI는 연결입니다 ",
		exp: `어니스트AI 온라인투자연계금융 플랫폼을 통해 대출자와 투자자가 연결됩니다. 대출상품의 수익, 리스크 등 종합 정보가 제공되고, 투자자는 원하는 대출상품을 선택하여 연결되는 기존에 없던 대체투자 플랫폼입니다.`,
		img: ic_credit,
	},
	{
		title: "기술로 리스크를 통제하여 초과수익 달성",
		overline: "어니스트AI는 혁신입니다",
		exp: `어니스트AI 대체투자 플랫폼은 혁신기술로 대출상품을 만들어 냅니다. 자체 선정산 시스템으로 구현한 SCF상품, 탁월한 신용평가모형에 기반한 개인신용대출상품 등 기술 없이는 리스크 관리가 어려운 상품을 제공합니다.`,
		img: ic_AI,
	},
	{
		title: "위험과 수익의 균형점인 저위험 중수익 추구",
		overline: "어니스트AI는 균형입니다",
		exp: `어니스트AI 대체투자 플랫폼은 혁신기술에 담보를 더해 수익과 리스크 사이의 균형점을 찾습니다. 부동산, 확정매출채권 등 신뢰도 높은 담보를 활용하여 저위험-중수익을 달성하기 위한 다양한 대출상품을 제공합니다.`,
		img: ic_monitoring,
	},
];

export const brandFeatureList = [
	{
		title: "불확실성을 이해하고 제어하는 리스크 과학",
		overline: "어니스트AI는 변하지 않는 본질에 집중합니다",
		exp: `방대한 데이터에 대한 분석, 최신 알고리즘의 변용 연구와 원천기술 개발, 수많은 리스크 예측모형을 생산하는 인공지능 인프라 설계까지. 금융산업의 근원적 고민인 불확실성을 연구하여 과학적이고 구조적인 답을 제시합니다.
		`,
		img: ic_credit,
	},
	{
		title: "혁신을 원하는 금융사업자를 위한 기술플랫폼",
		overline: "어니스트AI는 함께 더 큰 변화를 만듭니다",
		exp: `카드사를 위한 리스크 솔루션이자 저축은행을 위한 대체투자 플랫폼. 리스크 과학을 필요로하는 금융사, 핀테크사, 플랫폼사에게 AI 기술을 이식하여 보다 전방위적으로 금융산업의 변화를 가속합니다. 산업을 대표하는 R&D 플랫폼이 되겠습니다.`,
		img: ic_AI,
	},
];

export const brandFeatureMobileList = [
	{
		title: `불확실성을 이해하고\n제어하는 리스크 과학`,
		overline: "어니스트AI는\n변하지 않는 본질에 집중합니다",
		exp: `방대한 데이터에 대한 분석, 최신 알고리즘의 변용 연구와 원천기술 개발, 수많은 리스크 예측모형을 생산하는 인공지능 인프라 설계까지. 금융산업의 근원적 고민인 불확실성을 연구하여 과학적이고 구조적인 답을 제시합니다.
		`,
		img: ic_credit_white,
	},
	{
		title: `혁신을 원하는 금융사업자를\n위한 기술플랫폼`,
		overline: `어니스트AI는\n함께 더 큰 변화를 만듭니다`,
		exp: `카드사를 위한 리스크 솔루션이자 저축은행을 위한 대체투자 플랫폼. 리스크 과학을 필요로하는 금융사, 핀테크사, 플랫폼사에게 AI 기술을 이식하여 보다 전방위적으로 금융산업의 변화를 가속합니다. 산업을 대표하는 R&D 플랫폼이 되겠습니다.`,
		img: ic_AI_white,
	},
];
