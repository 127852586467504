import { useState } from "react";
import {
	Flex,
	Stack,
	Image,
	Button,
	Group,
	Title,
	Text,
	em,
	Modal,
	UnstyledButton,
} from "@mantine/core";
import styled from "styled-components";
import ic_license from "../../assets/service/on2up_license.png";
import ic_play_store from "../../assets/store/google_play_light.svg";
import ic_play_store_square from "../../assets/store/google_play_light_square.svg";
import ic_app_store from "../../assets/store/app_store_light.svg";
import ic_app_store_square from "../../assets/store/app_store_light_square.svg";
import { useMediaQuery } from "@mantine/hooks";
import qr_invest_google from "../../assets/qr/QR_invest_google.svg";
import qr_invest_appstore from "../../assets/qr/QR_invest_appstore.svg";
import qr_loan_google from "../../assets/qr/QR_loan_google.svg";

const TitleSection = styled(Stack)`
	@media only screen and (max-width: 750px) {
		position: absolute;
		width: calc(100% - 48px);
		left: 24px;
		top: 80px;
	}
`;

const AppLinkBar = styled(Flex)`
	@media only screen and (max-width: 750px) {
		position: absolute;
		width: calc(100% - 48px);
		left: 24px;
		bottom: 16px;
	}
`;

const TextBox = styled(Text)`
	padding: 2px;
	word-break: auto-phrase !important;
`;

const Store = styled(UnstyledButton)`
	border-radius: 4px;
	width: calc(50% - 4px);
	height: 60px;
	overflow: hidden;
	background-color: #dbe3e7;
	text-align: center;
	img {
		margin: auto;
	}
	@media only screen and (max-width: 750px) {
		width: 60px;
		height: 60px;
		background-color: #DBE3E7;
	}
`;

export const ServiceInfo = ({
	logo,
	serviceTitle,
	color,
	isLicense,
	title,
	title2,
	description,
	isAppstore,
	isGooglePlay,
	appStoreLink,
	googlePlayLink,
	serviceType,
}) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	const IconGooglePlay = ({ w, isMobile, onClick }) => (
		<Store w={w} onClick={onClick} align={"center"} justify={"center"}>
			<Image
				h={isMobile ? "auto" : 40}
				w={"auto"}
				src={isMobile ? ic_play_store_square : ic_play_store}
			/>
		</Store>
	);
	const IconAppStore = ({ w, isMobile, onClick }) => (
		<Store onClick={onClick} w={w} align={"center"} justify={"center"}>
			<Image
				h={isMobile ? "auto" : 40}
				w={"auto"}
				src={isMobile ? ic_app_store_square : ic_app_store}
			/>
		</Store>
	);

	const [isInvestGoogle, setIsInvestGoogle] = useState(false);
	const [isInvestAppStore, setIsInvestAppStore] = useState(false);
	const [isLoanGoogle, setIsLoanGoogle] = useState(false);

	const ModalQR = ({ opened, onClose, img, title, exp }) => (
		<Modal radius={16} w={360} opened={opened} onClose={onClose} centered>
			<Flex
				p={20}
				pt={0}
				pb={16}
				direction={"column"}
				align={"center"}
				justify={"center"}
				gap={16}
			>
				<Title w={"100%"} ta={"left"} order={isMobile ? 4 : 3}>
					{title}
				</Title>
				<Text ta={"left"} mb={24} size={isMobile ? "lg" : "xl"}>
					{exp}
				</Text>
				<Image src={img} w={200} h={200} />
			</Flex>
		</Modal>
	);

	const ModalQRInvestGoogle = () => (
		<ModalQR
			title={"어니스트펀드 모바일 간편투자"}
			exp={
				"QR코드를 촬영하시면 앱을 다운받을 수 있는 구글 플레이스토어로 이동합니다"
			}
			img={qr_invest_google}
			opened={isInvestGoogle}
			onClose={() => {
				setIsInvestGoogle(false);
			}}
		/>
	);

	const ModalQRInvestAppStore = () => (
		<ModalQR
			title={"어니스트펀드 모바일 간편투자"}
			exp={
				"QR코드를 촬영하시면 앱을 다운받을 수 있는 애플 앱스토어로 이동합니다"
			}
			img={qr_invest_appstore}
			opened={isInvestAppStore}
			onClose={() => {
				setIsInvestAppStore(false);
			}}
		/>
	);

	const ModalQRLoanGoogle = () => (
		<ModalQR
			title={"어니스트펀드 대출 앱 서비스"}
			exp={
				"QR코드를 촬영하시면 앱을 다운받을 수 있는 구글 플레이스토어로 이동합니다"
			}
			img={qr_loan_google}
			opened={isLoanGoogle}
			onClose={() => {
				setIsLoanGoogle(false);
			}}
		/>
	);

	const openLinkInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

	const linkInvestGoogle = 'https://play.google.com/store/apps/details?id=kr.honestfund.Honestfund&hl=ko-KR';
	const linkInvestAppStore = 'https://apps.apple.com/app/id1478604624';
	const linkLoanGoogle = 'https://play.google.com/store/apps/details?id=kr.honestfund.loan&hl=ko-KR';

	return (
		<Flex
			w={isMobile ? "100%" : 480}
			h={isMobile ? "100%" : 768}
			pt={isMobile ? 80 : 0}
			pb={48}
			direction={"column"}
			justify={"space-between"}
			gap={48}
		>
			<ModalQRInvestGoogle />
			<ModalQRLoanGoogle />
			<ModalQRInvestAppStore />
			{/* Content */}
			<Stack gap={isMobile ? 24 : 64}>
				{/* Title */}
				<TitleSection gap={isMobile ? 6 : 24}>
					<Flex
						align={"center"}
						justify={isMobile ? "center" : "start"}
						gap={8}
					>
						<Image
							src={logo}
							h={isMobile ? 34 * 0.7 : 34}
							w={isMobile ? 188 * 0.7 : 188}
							mb={isMobile ? 4 : 0}
						/>
						{!isMobile && (
							<Title order={isMobile ? 5 : 4} pt={4} c={color}>
								· {serviceTitle}
							</Title>
						)}
					</Flex>
					<Title
						style={{ whiteSpace: "break-spaces" }}
						ta={isMobile ? "center" : "left"}
						order={isMobile ? 3 : 1}
					>
						{title}
					</Title>
					{isMobile && (
						<Title order={4} size={18} fw={900} ta={"center"} c={"blue.6"}>
							{title2}
						</Title>
					)}
				</TitleSection>
				{/* // Description */}
				{!isMobile && (
					<TextBox size={"xl"}>
						{description}
						{isLicense && (
							<Image mt={16} src={ic_license} h={116 * 0.5} w={522 * 0.5} />
						)}
					</TextBox>
				)}
			</Stack>
			{/* // Links */}
			<AppLinkBar>
				{isAppstore && isGooglePlay && (
					<Stack w={"100%"} gap={12}>
						{!isMobile && (
							<>
								<Group gap={8}>
									<IconGooglePlay
										onClick={() => {
											if (serviceType === "invest") {
												setIsInvestGoogle(true);
											} else if (serviceType === "loan") {
												setIsLoanGoogle(true);
											}
										}}
										isMobile={isMobile}
									/>
									<IconAppStore
										onClick={() => {
											if (serviceType === "invest") {
												setIsInvestAppStore(true);
											}
										}}
										isMobile={isMobile}
									/>
								</Group>
								<Button
									component="a"
									target="_blank"
									href={"http://honestfund.kr"}
									size={"xl"}
									variant="filled"
									w={"100%"}
								>
									어니스트펀드 웹 바로가기
								</Button>
							</>
						)}
						{isMobile && (
							<Flex gap={8} w={"100%"}>
								<Button
									component="a"
									target="_blank"
									href={"http://honestfund.kr"}
									size={"xl"}
									variant="filled"
									w={"calc(100% - 132px)"}
								>
									어니스트펀드 웹
								</Button>
								<IconGooglePlay
									onClick={() => {
										if (serviceType === "invest") {
											openLinkInNewTab(linkInvestGoogle)
										} else if (serviceType === "loan") {
											openLinkInNewTab(linkLoanGoogle)
										}
									}}
									isMobile={isMobile}
								/>
								<IconAppStore
									onClick={() => {
										if (serviceType === "invest") {
											openLinkInNewTab(linkInvestAppStore)
										}
									}}
									isMobile={isMobile}
								/>
							</Flex>
						)}
					</Stack>
				)}
				{!isAppstore && isGooglePlay && (
					<Stack w={"100%"} gap={12}>
						{!isMobile && (
							<Group gap={8}>
								<IconGooglePlay
									w={"calc(40% - 8px)"}
									onClick={() => {
										if (serviceType === "invest") {
											setIsInvestGoogle(true);
										} else if (serviceType === "loan") {
											setIsLoanGoogle(true);
										}
									}}
									isMobile={isMobile}
								/>
								<Button
									component="a"
									target="_blank"
									href={"http://honestfund.kr"}
									size="xl"
									variant="filled"
									w={"60%"}
								>
									어니스트펀드 웹 바로가기
								</Button>
							</Group>
						)}
						{isMobile && (
							<Group gap={8}>
								<Button
									component="a"
									target="_blank"
									href={"http://honestfund.kr"}
									size="xl"
									variant="filled"
									w={"calc(100% - 68px)"}
								>
									어니스트펀드 웹
								</Button>
								<IconGooglePlay
									onClick={() => {
										if (serviceType === "invest") {
											openLinkInNewTab(linkInvestGoogle)
										} else if (serviceType === "loan") {
											openLinkInNewTab(linkLoanGoogle)
										}
									}}
									isMobile={isMobile}
								/>
							</Group>
						)}
					</Stack>
				)}
			</AppLinkBar>
		</Flex>
	);
};
