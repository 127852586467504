import styled from "styled-components";
import logo_Honest from "../../assets/logo/logo-grid-honest.svg";
import logo_symbol from "../../assets/logo/logo-grid-symbol.svg";
import logo_AI from "../../assets/logo/logo-grid-ai.svg";
import { Flex } from "@mantine/core";
import { Image } from "@mantine/core";
import { blue } from "../../designTokens/colors";
import { red } from "../../designTokens/colors";

const LogoUnit = styled(Image)`
	position: absolute;
	top: ${(props) => `${props.y}px`};
	left: ${(props) => `${props.x}px`};
`;

const LogoContainer = styled(Flex)`
	position: absolute;
	top: ${(props) => `${props.y}px`};
	left: ${(props) => `${props.x}px`};
`;

export const LogoGrid = ({ scale = 1, mode = "dark", ease }) => {
	const width = 640;
	const height = 104;
	return (
		<Flex w={width} h={height} style={{ position: "relative" }}>
			<LogoUnit w={453} h={104} x={0} y={0} src={logo_Honest} />
			<LogoUnit w={32} h={32} x={438} y={71} src={logo_symbol} />
			<LogoContainer w={158} h={101} x={482} y={0}>
				<svg
					width="158"
					height="101"
					viewBox="0 0 158 101"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<defs>
						<linearGradient id="grad1" gradientTransform="rotate(-30)">
							<stop offset="0%" stop-color={blue[6]}>
								<animate
									attributeName="stop-color"
									values={`${blue[6]};${blue[6]};${blue[3]};${blue[6]};${blue[6]}`}
									dur="5s"
									repeatCount="indefinite"
								/>
							</stop>
							<stop offset="100%" stop-color={blue[3]}>
								<animate
									attributeName="stop-color"
									values={`${blue[3]};${blue[6]};${blue[6]};${blue[6]};${blue[3]}`}
									dur="5s"
									repeatCount="indefinite"
								/>
							</stop>
						</linearGradient>
					</defs>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M77.2079 101H97.0004L60.6704 0H36.3924L0.000366211 101H19.5936L29.4057 72H67.3958L77.2079 101ZM34.7057 56L48.0369 16.0578H48.7732L62.1129 56H34.7057ZM158 0V16H142V85H158V101H107V85H123V16H107V0H158Z"
						fill="url(#grad1)"
					/>
				</svg>
			</LogoContainer>
			{/* <LogoUnit w={158} h={101} x={482} y={0} src={logo_AI} /> */}
		</Flex>
	);
};
