import { Flex, em } from "@mantine/core";
import { AspectRatio } from "@mantine/core";
import { FadeIn } from "../../../components/triggerWrapper/FadeIn";
import { useMediaQuery } from "@mantine/hooks";

// import video1 from "../../../assets/video/lending_AI_credit.mp4";
// import video2 from "../../../assets/video/lending_renewal.mp4";
// import video3 from "../../../assets/video/lending_curation.mp4";

const video1 = 'https://hf-product.s3.ap-northeast-2.amazonaws.com/brand/performance_monitoring.gif';
const video2 = 'https://hf-product.s3.ap-northeast-2.amazonaws.com/brand/dag.gif';
const video3 = 'https://hf-product.s3.ap-northeast-2.amazonaws.com/brand/strategy_opt.gif';
const videoList = [video1, video2, video3];
	
export const ScreenLending = ({ isTrigger,currentVideo }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	return (
		<Flex style={{borderRadius:isMobile?4:8, overflow:' hidden'}}>
			<FadeIn duration={"0.2s"} isTrigger={isTrigger}>
				<AspectRatio bg={'black'} ratio={650 / 370}>
					<img src={videoList[currentVideo]} />
				</AspectRatio>
			</FadeIn>
		</Flex>
	);
};
