import { SectionContainer } from "../../components/container/SectionContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { Flex, em } from "@mantine/core";
import { CardFeature } from "../../components/card/CardFeature";
import { CardFeatureBig } from "../../components/card/CardFeatureBig";
import { brandFeatureList } from "../../data/brandFeatureList";
import { useMediaQuery } from "@mantine/hooks";
import styled from "styled-components";


const CardContainer = styled(Flex)`
	@media only screen and (max-width: 750px) {
		position: absolute;
		left: -6px;
		bottom: 24px;
	}
`;

export const AITransitionSection = ({ isTrigger, transitionDelay, type }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	return (
		<SectionContainer>
			<ContentContainer h={"100%"}>
				<Flex w={"100%"} h={"100%"}>
					{!isMobile && <Flex w={"50%"}></Flex>}
					<Flex
						w={isMobile ? "100%" : "50%"}
						h={isMobile ? "100%" : "auto"}
						direction={"column"}
						align={"end"}
					>
						<CardContainer
							direction={"column"}
							gap={20}
							pt={48}
							pr={isMobile ? 24 : 72}
							pl={isMobile ? 24 : 0}
						>
							{brandFeatureList.map((item, i) => (
								<CardFeatureBig
									delay={transitionDelay}
									title={item.title}
									overline={item.overline}
									exp={item.exp}
									img={item.img}
									key={i}
									isTrigger={isTrigger}
									index={i}
								/>
							))}
						</CardContainer>
					</Flex>
				</Flex>
			</ContentContainer>
		</SectionContainer>
	);
};
