export const companyHistoryList = [
	{
		// index: 0 last time slot of 2023 - 2024
		year: 2024,
		date: "2024년 3월 ",
		title: "대출 승인전략 최적화 기술 개발 및 특허 출원",
	},
	{
		year: 2024,
		date: "2024년 1월 ",
		title: "경기상황 자동 반영 신용평가모형 기술 개발 및 특허 등록",
	},
	{
		year: 2024,
		date: "2024년 1월 ",
		title: "2023년 D-testbed 금융위원장상 대상 수상",
	},
	{
		year: 2023,
		date: "2023년 12월",
		title: "SK그룹 계열회사와 특화 AI모형 개발 및 공급 (임차인 Tenant Screening)",
	},
	{
		year: 2023,
		date: "2023년 12월",
		title: "AI 신용평가사업 관련 전략적 투자(SI) 유치 (누적 투자유치액 442억원)",
	},
	{
		year: 2023,
		date: "2023년 12월",
		title: "국내 Top 5 개인신용대출 취급 저축은행과 Lending Intelligence 솔루션 공급계약 체결",
	},
	{
		year: 2023,
		date: "2023년 9월",
		title: "국내 주요금융그룹 저축은행과 Lending Intelligence 솔루션 공급계약 체결",
	},
	{
		year: 2023,
		date: "2023년 9월",
		title: "인공지능 기반의 연속학습 데이터 기술 개발 및 특허 등록",
	},
	{
		year: 2023,
		date: "2023년 3월",
		title: "금융기관과 사업/기술 제휴를 위한 최초 업무협약 체결 ",
	},
	{
		year: 2023,
		date: "2023년 1월",
		title: "인공지능 여신리스크관리 솔루션 Lending Intelligence 개발 및 상용화",
	},
	{
		year: 2023,
		date: "2023년 1월",
		title: "인공지능 기반의 연합학습 데이터 기술 개발 및 특허 등록",
	},
	// index: 11 last time slot of 2021 - 2022
	{
		year: 2021,
		date: "2021년 12월",
		title: "인공지능 중금리 개인신용평가모델 3.0 개발",
	},
	{
		year: 2021,
		date: "2021년 8월",
		title: "금융위원회에 온라인투자연계금융업자 금융라이센스 취득",
	},
	{
		year: 2021,
		date: "2021년 1월",
		title: "연계대출 누적대출액 1조원 돌파",
	},
	// index: 14 last time slot of 2019 - 2020
	{
		year: 2019,
		date: "2019년 9월",
		title: "시리즈B+ 투자유치 (누적 투자유치액 332억원)",
	},
	// index: 15 last time slot of 2017 - 2018
	{
		year: 2018,
		date: "2018년 2월",
		title: "음성 데이터 기반 신용평가모형 개발 및 특허 등록",
	},
	{
		year: 2017,
		date: "2017년 12월 ",
		title: "중금리 개인신용대출 평가모형 CSS 2.0 개발",
	},
	{
		year: 2017,
		date: "2017년 8월",
		title: "매일경제 핀테크 어워드 우수상 수상 (P2P대출서비스 부문)",
	},
	{
		year: 2017,
		date: "2017년 6월",
		title: "자산총계 1위 은행 대상 대안신용평가모형 검증 ",
	},
	{
		year: 2017,
		date: "2017년 6월",
		title: "고금리 대부업시장을 대체하는 중금리 주택담보대출 상품 런칭",
	},
	{
		year: 2017,
		date: "2017년 1월",
		title: "2017년 1월 소상공인 공급망금융인 매출채권담보대출상품 런칭\n(누적 14만건 실행 완료)",
	},
	// index: 21 last time slot of 2015 - 2016
	{
		year: 2016,
		date: "2016년 9월",
		title: "시리즈A 투자유치 (누적 투자유치액 90억원)",
	},
	{
		year: 2016,
		date: "2016년 9월",
		title: "한국경제 핀테크대상 데이터분석·리스크 관리로 최우수상",
	},
	{
		year: 2016,
		date: "2016년 6월",
		title: "심리측정 기반의 신용평가모형 개발 및 특허 등록",
	},
	{
		year: 2015,
		date: "2015년 12월",
		title: "신한은행 등 Seed 라운드 투자유치 (업권 최초 제1금융권 투자 유치)",
	},
	{
		year: 2015,
		date: "2015년 12월",
		title: "비대면 대출인프라 및 코어뱅킹 시스템 최초구축",
	},
	{
		year: 2015,
		date: "2015년 5월",
		title: "신한퓨처스랩 1기 스타트업 우승",
	},
	{
		year: 2015,
		date: "2015년 5월",
		title: "중금리 개인신용대출 평가모형 CSS 1.0 개발",
	},
	{
		year: 2015,
		date: "2015년 2월",
		title: "법인 설립 (개인신용대출 상품 개발 및 취급으로 당시 P2P금융 사업 시작)",
	},
];
