import * as d3 from "d3";
import { Flex } from "@mantine/core";
import { useRef, useEffect, useState } from "react";
const colors = require("../../designTokens/colors");

export const Connector = ({
	id,
	startDelayUnit,
	zone,
	accentColor,
	isStop = false,
	row,
	currentSection
}) => {
	// Symbol Spec
	const width = 160;
	const height = 32;
	// const accentColor1 = colors.blue[1];
	const accentColor1 = accentColor;
	const accentColor2 = colors.blue[4];
	const timeUnit = 2400;
	const durationUnit = (timeUnit * zone) / 20;
	const delay = (timeUnit * 1) / zone;
	// const delay = 2400;
	const getDelay = () => {
		return Math.random() * delay + 600;
	};

	const ease = {
		// in: d3.easePolyIn.exponent(3),
		// out: d3.easePolyOut.exponent(3),
		in: d3.easeQuadIn,
		out: d3.easeQuadOut,
		bounce: d3.easeBounceInOut,
	};
	const scale = zone / 40 + 0.1;
	// const scale = 0.2;

	// Init SVG
	let svgRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);


	const [duration, setDuration] = useState(durationUnit);
	const [startDelay, setStartDelay] = useState(startDelayUnit);

	const getOpacity = (zone) => {
		if (zone === 6) {
			return 0;
		} else {
			return isStop ? zone / 16 : zone / 12;
			// return 1;
		}
	};

	const offset = 0;

	useEffect(() => {
		if (!isLoaded) {
			const svg = d3.select(svgRef.current);
			// svg.style("background-color","#00ffff");

			const g = svg
				.attr("class", "connector")
				.attr("width", width)
				.attr("height", height)
				.append("g")
				.attr("class", `conntector_group_${id}`)
				.attr("transform", `translate(${row % 2 === 0 ? offset : -offset},0)`);

			g.append("rect")
				.attr("class", `r1_${id}`)
				.attr("x", 0)
				.attr("y", 16)
				.attr("height", 16 * scale)
				.attr("width", 0)
				.style("fill", "url(#gradient1)")
				.attr("opacity", getOpacity(zone));

			g.append("rect")
				.attr("class", `r2_${id}`)
				.attr("x", width / 2)
				.attr("y", 16 - 16 * scale)
				.attr("height", 16 * scale)
				.attr("width", 0)
				.style("fill", "url(#gradient2)")
				.attr("opacity", getOpacity(zone));

			//Create Gradient
			const gradient1 = g
				.append("defs")
				.append("linearGradient")
				.attr("id", "gradient1")
				.attr("x1", "0%")
				.attr("y1", "0%")
				.attr("x2", "100%")
				.attr("y2", "0%");
			// .attr("spreadMethod", "pad");

			gradient1
				.append("stop")
				.attr("offset", "0%")
				.attr("stop-color", `${accentColor1}`)
				.attr("stop-opacity", 0.6);

			gradient1
				.append("stop")
				.attr("offset", "50%")
				.attr("stop-color", `${accentColor1}`)
				.attr("stop-opacity", 1);

			gradient1
				.append("stop")
				.attr("offset", "100%")
				.attr("stop-color", `${accentColor1}`)
				.attr("stop-opacity", 1);

			const gradient2 = g
				.append("defs")
				.append("linearGradient")
				.attr("id", "gradient2")
				.attr("x1", "0%")
				.attr("y1", "0%")
				.attr("x2", "100%")
				.attr("y2", "0%");
			// .attr("spreadMethod", "pad");

			gradient2
				.append("stop")
				.attr("offset", "0%")
				.attr("stop-color", `${accentColor2}`)
				.attr("stop-opacity", 0.6);

			gradient2
				.append("stop")
				.attr("offset", "50%")
				.attr("stop-color", `${accentColor2}`)
				.attr("stop-opacity", 1);

			gradient2
				.append("stop")
				.attr("offset", "100%")
				.attr("stop-color", `${accentColor2}`)
				.attr("stop-opacity", 1);

			repeatTransition1();
			repeatTransition2();

			setIsLoaded(true);
		}
	}, [isLoaded]);

	useEffect(() => {
		if (isLoaded && !isStop) {
			repeatTransition1();
			repeatTransition2();
			d3.select(`.conntector_group_${id}`)
				.transition()
				.attr("transform", `translate(${row % 2 === 0 ? offset : -offset},0)`);
		}
		if (isLoaded && isStop) {
			stopTransition1();
			stopTransition2();
		}
	}, [isStop, isLoaded, duration]);

	useEffect(() => {
		if(currentSection===0){
			setDuration(durationUnit);
			setStartDelay(startDelayUnit);
		}
		else{
			setDuration(durationUnit * 2);
			setStartDelay(startDelayUnit * 2);
		}
	}, [currentSection])

	const repeatTransition1 = () => {
		// console.log(duration);
		d3.select(`.r1_${id}`)
			.attr("opacity", getOpacity(zone))
			.transition()
			.attr("x", 0)
			.attr("width", 0)
			// Appear
			.transition()
			.ease(ease.out)
			.delay(startDelay)
			.duration(duration)
			.attr("width", width / 2)
			// Connect
			.transition()
			.ease(ease.in)
			.duration(duration)
			.attr("x", width / 2 - 16 * scale)
			.attr("width", 16 * scale)
			// Hide
			.transition()
			.ease(ease.out)
			// .delay(delay)
			.duration(duration)
			.attr("width", 0)
			.attr("x", width / 2)
			// Waiting
			.transition()
			.ease(ease.out)
			.delay(getDelay())
			.on("end", repeatTransition1);
	};

	const repeatTransition2 = () => {
		d3.select(`.r2_${id}`)
			.attr("opacity", getOpacity(zone))
			.transition()
			.attr("x", width / 2)
			.attr("width", 0)
			// Connect
			.transition()
			.ease(ease.in)
			.delay(startDelay + getDelay())
			.duration(duration/2)
			.attr("width", 16 * scale)
			// Apper
			.transition()
			.ease(ease.in)
			// .delay(delay)
			.duration(duration / 2)
			.attr("width", width / 2)
			// Hide
			.transition()
			.ease(ease.in)
			.duration(duration / 2)
			.attr("x", width)
			.attr("width", 0)
			// Waiting
			.transition()
			.ease(ease.out)
			.delay(delay / 2)
			.on("end", repeatTransition2);
	};

	const stopTransition1 = () => {
		d3.select(`.conntector_group_${id}`)
			.transition()
			.attr("transform", "translate(0,0)");

		d3.select(`.r1_${id}`)
			// Connect
			.transition()
			.ease(ease.bounce)
			.duration(duration / 4)
			.attr("opacity", getOpacity(zone))
			.attr("x", width / 2 - 16 * scale)
			.attr("width", 16 * scale);
	};

	const stopTransition2 = () => {
		d3.select(`.conntector_group_${id}`)
			.transition()
			.attr("transform", "translate(0,0)");

		d3.select(`.r2_${id}`)
			// Connect
			.transition()
			.ease(ease.bounce)
			.duration(duration / 4)
			.attr("opacity", getOpacity(zone))
			.attr("width", 16 * scale);
	};

	return (
		<>
			<svg ref={svgRef} />
			{/* <Flex w={160} h={32} align={'center'} justify={'center'} c={'white'}>
			{row}
			</Flex> */}
		</>
	);
};
