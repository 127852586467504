import { Flex, em } from "@mantine/core";
import styled from "styled-components";

const Container = styled(Flex)`
	background: radial-gradient(
		101.16% 139.86% at 0% 2.22%,
		#B3C7FF 0%,
		#beebe7ff 100%
	);
	padding: 8px;
	border-radius: 16px;
	transition: all 0.2s ease-in-out;
	overflow: hidden;
	@media only screen and (max-width: 750px) {
		border-radius: 8px;
		padding: 4px;
		/* border-radius: 16px 16px 0px 9px; */
		mask-image: linear-gradient(
			to top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) 15%,
			ㅏ rgba(0, 0, 0, 1) 85%,
			rgba(0, 0, 0, 1) 100%
		);
	}
`;

export const DeviceFrame = ({ children, w, h,style }) => {
	return (
		<Container w={w} h={h} style={style}>
			{children}
		</Container>
	);
};
