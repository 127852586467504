import styled from "styled-components";
// Hook
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { useViewPortLoaded } from "../hook/useViewPortLoaded";
// Package
import ReactFullpage from "@fullpage/react-fullpage";
import { TypeAnimation } from "react-type-animation";
// Core
import { Container, Flex, Title } from "@mantine/core";
import { Space, em } from "@mantine/core";
// Interactive & Floating Element
import { TopHeroSection } from "../template/brand/TopHeroSection";
import { TextLoopSection2 } from "../template/brand/TextLoopSection2";
import { AIInteractiveText } from "../template/brand/AIInteractiveText";
import { InteractiveScreen } from "../template/service/InteractiveScreen";
// Component
import { SectionContainer } from "../components/container/SectionContainer";
import { Header } from "../components/navigation/Header";
// Section
import { AITransitionSection } from "../template/brand/AITransitionSection";
import { LendingIntelligenceSection } from "../template/service/LendingIntelligenceSection";
import { HonestFundSection } from "../template/service/HonestFundSection";
import { HonestFundLoanSection } from "../template/service/HonestFundLoanSection";
import { StakeHolderSection } from "../template/company/StakeHolderSection";
import { CompanyHistorySection } from "../template/company/CompanyHistorySection";
import { MediaSection } from "../template/company/MediaSection";
import { FooterSection } from "../template/footer/FooterSection";

// Text With Shadow For Brand MSG @Landing Section
const WithShadow = styled(Title)`
	text-shadow:
		0px 0px 48px ${(props) => props.shadowColor},
		0px 0px 24px #000000;
	opacity: ${(props) => (props.isTrigger ? 1 : 0)};
	text-align: center;
	padding: 0px 12px;
	transition: all 0.3s ease-in-out 0.4s;
`;

export const PageMainFullPageVer2 = ({}) => {
	// Viewport Control
	// Component
	const { width, isLoaded } = useViewPortLoaded();

	// Ref For Fullpage js API
	const fullpageApiRef = useRef(null);
	let fullpageFunction;

	// Fullpage Section State
	const [currentSection, setCurrentSection] = useState(-1);
	const [nextSection, setNextSection] = useState(-1);

	// Delay
	const delayShort = "0.4s";
	const delayLong = "1.5s";
	const [s2Delay, setS2Delay] = useState("0s");
	const [s3Delay, setS3Delay] = useState("0s");
	const [aiTextDelay, setAiTextDelay] = useState("0s");

	//Previous Section Control
	const [isS1Unmmounted, setIsS1Unmounted] = useState(false);
	const [isS2Unmmounted, setIsS2Unmounted] = useState(false);
	const [isS3Unmmounted, setIsS3Unmounted] = useState(false);
	const [s3Layout, setS3Layoout] = useState("big");

	//Breakpoint setting
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	// Lending Intelligence Video Switch
	// LendingIntelligenceSection -> InteractiveScreen
	const [currentVideo, setCurrentVideo] = useState(0);

	// Previous Section Handler
	useEffect(() => {
		// Hero Fade to Section1
		// Logo Text Part Fading
		// Make Symbol Center
		// BG Fadding

		// Hero Section Fade Out
		// 0 -> 1
		if (currentSection === 0 && nextSection === 1) {
			setIsS1Unmounted(true);
			setIsS2Unmounted(false);
		}

		// Text Loop Section Fade Out
		// 1 -> 2
		if (currentSection === 1 && nextSection === 2) {
			setIsS2Unmounted(true);
			setIsS3Unmounted(false);
		}

		// Hero Section Fade In
		// 1 -> 0
		if (currentSection === 1 && nextSection === 0) {
			setIsS1Unmounted(false);
			setIsS2Unmounted(true);
		}
		// Text Loop Section Fade Out
		// 2 -> 1
		if (currentSection === 2 && nextSection === 1) {
			setIsS2Unmounted(false);
			setIsS3Unmounted(true);
		}
	}, [nextSection]);

	// Current Section Handdler
	useEffect(() => {
		if (currentSection === 0) {
			setIsS1Unmounted(false);
		}
		// console.log(currentSection);
	}, [currentSection]);

	const handleBeforePageChnage = (origin, destination) => {
		// Text Loop Section Delay - s1
		console.log(`next: ${destination.index}`);
		if (destination.index === 0 || destination.index === 2) {
			setS2Delay("0s");
		} else {
			setS2Delay(delayShort);
		}
		// AI - 1 Delay - s2
		if (destination.index === 1 || destination.index === 3) {
			setS3Delay("0s");
			setAiTextDelay("0s");
		} else {
			setS3Delay(delayLong);
			setAiTextDelay(delayShort);
		}
		setNextSection(destination.index);
	};
	const handlePageChange = (origin, destination) => {
		console.log(`current: ${destination.index}`);
		setNextSection(destination.index);
		setCurrentSection(destination.index);
	};

	// Create a ref to the DOM element
	const scrollContainerRef = useRef(null);
	// Create a ref to the target element
	const stakeHolderRef = useRef(null);
	const historyRef = useRef(null);
	const mediaRef = useRef(null);
	const ContactRef = useRef(null);

	const onChangeSection = (index) => {
		// Full Page Scroll 1st
		if (index < 7) {
			fullpageFunction.moveTo(index, 0);
		} else {
			fullpageFunction.moveTo(7, 0);
		}

		// Natural Scroll Before Full Page Scroll
		setTimeout(() => {
			if (index === 7) {
				historyRef.current.scrollIntoView({ behavior: "smooth" });
			} else if (index === 8) {
				mediaRef.current.scrollIntoView({ behavior: "smooth" });
			} else if (index === 9) {
				ContactRef.current.scrollIntoView({ behavior: "smooth" });
			}
		}, 800);
	};
	const licenseKey = process.env.REACT_APP_FULLPAGE_LICENSE_KEY || "YOUR_KEY";

	return (
		<Container w={"100%"}>
			{isLoaded && (
				<>
					<Header
						nextSection={nextSection}
						onChangeSection={onChangeSection}
						currentSection={currentSection}
						isTrigger={0 < currentSection}
					/>
					{/* Section-0: Hero */}
					<TopHeroSection
						unmounted={isS1Unmmounted}
						isTrigger={currentSection < 2}
						currentSection={currentSection}
						nextSection={nextSection}
					/>
					{/* Section-1: Brand Features */}
					<TextLoopSection2
						isTrigger={nextSection === 1}
						isTrigger2={currentSection === 2}
						unmounted={isS2Unmmounted}
						transitionDelay={s2Delay}
					/>
					{/* Section-2: AI transition Section */}
					<AIInteractiveText
						isTrigger={currentSection === 2}
						currentSection={currentSection}
						nextSection={nextSection}
						transitionDelay={aiTextDelay}
						type={s3Layout}
					/>
					{/* Floating Screen For Service Section */}
					{/* Lending Intelligence */}
					{/* Honest Fund - Loan & Invest */}
					{!isMobile && (
						<InteractiveScreen
							isTrigger={2 < nextSection && nextSection < 6}
							currentSection={currentSection}
							nextSection={nextSection}
							currentVideo={currentVideo}
						/>
					)}

					<ReactFullpage
						//fullpage options
						licenseKey={licenseKey}
						credits={{ enabled: false, label: "", position: "right" }}
						scrollingSpeed={600} /* Options here */
						onLeave={handleBeforePageChnage}
						afterLoad={handlePageChange}
						render={({ state, fullpageApi }) => {
							fullpageApiRef.current = fullpageApi;
							fullpageFunction = fullpageApi;
							return (
								<ReactFullpage.Wrapper>
									<SectionContainer>
										<WithShadow
											style={{ marginTop: 240 }}
											fw={900}
											c={"white"}
											order={isMobile ? 4 : 3}
											shadowColor={"#ffffff50"}
											isTrigger={true}
										>
											<TypeAnimation
												sequence={[
													isMobile
														? `금융과 기술을 연결하는\n가장 정직한 파트너가 되겠습니다`
														: `금융과 기술을 연결하는 가장 정직한 파트너가 되겠습니다`,
													1000,
												]}
												style={{ whiteSpace: "break-spaces" }}
												speed={50}
												repeat={1}
											/>
										</WithShadow>
									</SectionContainer>
									<SectionContainer></SectionContainer>
									<AITransitionSection
										isTrigger={nextSection === 2}
										type={s3Layout}
										transitionDelay={s3Delay}
									/>
									<LendingIntelligenceSection
										isTrigger={nextSection === 3}
										onChangeVideo={setCurrentVideo}
									/>
									<HonestFundLoanSection
										currentSection={currentSection}
										isTrigger={nextSection === 4}
									/>
									<HonestFundSection
										currentSection={currentSection}
										isTrigger={nextSection === 5}
									/>
									{/* Natural Scroll Area */}
									{/* // Section6 */}
									<Flex
										className="section"
										ref={scrollContainerRef}
										w={isMobile ? width : "100%"}
										direction={"column"}
									>
										<Space h={isMobile ? 120 : 180} />
										<div ref={stakeHolderRef}></div>
										<StakeHolderSection
											theme={"dark"}
										/>
										<Space h={isMobile ? 60 : 120} />
										{/* Section 7: Dark Section */}
										<div ref={historyRef}></div>
										<CompanyHistorySection
											isTrigger={true}
										/>
										<Space h={isMobile ? 160 : 180} />
										<div ref={mediaRef}></div>
										<MediaSection defaultValue={"All"} />
										<Space h={isMobile ? 180 : 360} />
										<div ref={ContactRef}></div>
										<FooterSection isTrigger={nextSection === 9} />
									</Flex>
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				</>
			)}
		</Container>
	);
};
