module.exports = {
	white: [
		"#ffffff",
		"#e6e6e6",
		"#cccccc",
		"#b3b3b3",
		"#999999",
		"#808080",
		"#666666",
		"#4d4d4d",
		"#333333",
		"#000000",
	],
	red: [
		"#fcf3f2ff",
		"#fadcd9ff",
		"#fabbb4ff",
		"#fc9086ff",
		"#fa5343ff",
		"#d91f11ff",
		"#a1160aff",
		"#75160cff",
		"#4f150fff",
		"#24120cff",
	],
	orange: [
		"#fcf2ebff",
		"#fcddc7ff",
		"#fcbc97ff",
		"#fc9162ff",
		"#e86427ff",
		"#bf4815ff",
		"#8f3415ff",
		"#632b17ff",
		"#402117ff",
		"#1f1410ff",
	],
	yellow: [
		"#FFFCF5",
		"#FFF1CC",
		"#FFE499",
		"#FFD666",
		"#FFC933",
		"#FFBB00",
		"#CC9600",
		"#997000",
		"#664B00",
		"#332500",
	],
	lime: [
		"#ebf7daff",
		"#d5f0b1ff",
		"#aad971ff",
		"#78bf39ff",
		"#52a31dff",
		"#3c7d0eff",
		"#2e5c0eff",
		"#254211ff",
		"#1c2e10ff",
		"#121a0dff",
	],
	green: [
		"#ebf7edff",
		"#c7ebd1ff",
		"#88dba8ff",
		"#43c478ff",
		"#16a163ff",
		"#077d55ff",
		"#075e45ff",
		"#094536ff",
		"#092e25ff",
		"#081a15ff",
	],
	teal: [
		"#ebf5f4ff",
		"#beebe7ff",
		"#86d9d4ff",
		"#4ebfb9ff",
		"#279c9cff",
		"#167b7dff",
		"#155c5eff",
		"#124241ff",
		"#102e2dff",
		"#0c1a19ff",
	],
	turquoise: [
		"#ebf5f4ff",
		"#c7e8edff",
		"#81d8e5ff",
		"#45bcd1ff",
		"#159ab2ff",
		"#067a91ff",
		"#09596bff",
		"#0c424dff",
		"#102d33ff",
		"#0f181aff",
	],
	aqua: [
		"#ebf3f7ff",
		"#c9e7f5ff",
		"#8bd3f7ff",
		"#48b8f0ff",
		"#1195d6ff",
		"#0073baff",
		"#08548aff",
		"#0e3d66ff",
		"#0c2b45ff",
		"#0b1724ff",
	],
	blue: [
		"#E6ECFF",
		"#B3C7FF",
		"#80A2FF",
		"#4D7CFF",
		"#1A57FF",
		"#0044FF",
		"#0036CC",
		"#002999",
		"#001B66",
		"#000E33",
	],
	ultramarine: [
		"#EBECFF",
		"#D7D9FF",
		"#B1B2FF",
		"#7F73FE",
		"#643DF8",
		"#5A00F4",
		"#4100B5",
		"#1E005E",
		"#09002B",
		"#120033",
	],
	purple: [
		"#f7f2fcff",
		"#eadcfcff",
		"#dabefaff",
		"#c89afcff",
		"#ac71f0ff",
		"#8f49deff",
		"#6b30abff",
		"#4c277dff",
		"#331f4dff",
		"#1c1229ff",
	],
	pink: [
		"#fcf0f8ff",
		"#f7daedff",
		"#f7b7e2ff",
		"#fa87d4ff",
		"#ed4cb7ff",
		"#cc1d92ff",
		"#961574ff",
		"#6b155aff",
		"#47153fff",
		"#241020ff",
	],
	gray: [
		"#F2F5F7",
		"#DCE3E8",
		"#C1CCD6",
		"#9FB1BD",
		"#7A909E",
		"#5B7282",
		"#3E5463",
		"#2A3F4D",
		"#1C2B36",
		"#0C1230",
		"#030304",
	],
};
