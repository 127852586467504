import { SectionContainer } from "../../components/container/SectionContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { Flex, em } from "@mantine/core";
import { FadeInToTop } from "../../components/triggerWrapper/FadeInToTop";
import { ServiceInfo } from "./ServiceInfo";
import ic_honest_fund from "../../assets/service/logo_honest_fund.svg";
import { useMediaQuery } from "@mantine/hooks";
import { DeviceFrame } from "../../components/container/DeviceFrame";
import { ScreenHonestMobile } from './screen/ScreenHonestMobile';
import styled from "styled-components";

const MobileScreenContainer = styled(Flex)`
	position: absolute;
	bottom: 80px;
	left: calc(50% - 160px);
	width: 320px;
	height: 320px;
	overflow: hidden;
	flex-direction: column;
	justify-content: flex-start;
	mask-image: linear-gradient(
			to top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) 15%,
			rgba(0, 0, 0, 1) 85%,
			rgba(0, 0, 0, 1) 100%
		);
`;

export const HonestFundSection = ({ isTrigger, currentSection }) => {
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
	const description = `중금리대출을 희망하는 개인과 법인에게 모바일 앱을 통해 직접 투자하고 이자 수익을 얻을 수 있습니다. 고객군과 채널의 확장을 희망하는 금융기관에게 특화 투자 플랫폼을 제공합니다.`;
	return (
		<SectionContainer>
			<ContentContainer>
				<Flex w={"100%"} h={"100%"}>
					{!isMobile && (
						<Flex
							w={"50%"}
							direction={"column"}
							align={"center"}
							justify={"center"}
						></Flex>
					)}
					<Flex
						w={isMobile ? "100%" : "50%"}
						direction={"column"}
						align={"center"}
						p={isMobile?24:0}
					>
						<FadeInToTop duration={"1s"} isTrigger={isTrigger}>
							<ServiceInfo
								logo={ic_honest_fund}
								serviceTitle={"어니스트펀드 / 투자"}
								color={"#0443A1"}
								isLicense
								isAppstore
								isGooglePlay
								title={isMobile?"누구나 소액부터 시작하는\n간편 대체투자 서비스":"누구나\n소액부터 시작하는\n간편 대체투자 서비스"}
								title2={"어니스트펀드 / 투자"}
								description={description}
								serviceType={'invest'}
							/>
						</FadeInToTop>
						{isMobile && (
							<MobileScreenContainer>
								<DeviceFrame w={320}>
									<ScreenHonestMobile isTrigger={currentSection === 5} />
								</DeviceFrame>
							</MobileScreenContainer>
						)}
					</Flex>
				</Flex>
			</ContentContainer>
		</SectionContainer>
	);
};
