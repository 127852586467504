import { useState } from "react";
import { SectionContainer } from "../../components/container/SectionContainer";
import { ContentContainer } from "../../components/container/ContentContainer";
import { Flex } from "@mantine/core";
import { DeviceFrame } from "../../components/container/DeviceFrame";
import { Image } from "@mantine/core";
import logo_lending_intelligence from "../../assets/logo/logo_lending_intelligence.svg";
import logo_ll from "../../assets/logo/LI_Logo.svg";
import { Title } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { Text, em } from "@mantine/core";
import styled from "styled-components";
import { FadeInToTop } from "../../components/triggerWrapper/FadeInToTop";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenLending } from "./screen/ScreenLending";
import { Divider } from "@mantine/core";
import { Stack } from "@mantine/core";

const TextBox = styled(Text)`
	padding: 2px;
	word-break: auto-phrase !important;
`;

const contentList = [
	`개별 금융기관에서 R&D 채산성이 나오기 어려운 다양한 AI 신용평가 모형을 개발하여 여러가지 리스크에 대한 적확한 대응을 지원합니다. 기존 신용평가시스템에 AI 모델을 더하여 공격적인 영업 확장 속에서도 더 낮은 부실률 달성이 가능해집니다.`,
	`독자 개발한 MLOps AI 파이프라인을 활용하여 최신 데이터를 애자일하게 반영합니다. 시장의 변화를 지속 감지하고 AI 모델을 빠른 주기로 업데이트함으로써, 새롭게 이슈가 되는 리스크에 대해서도 적시 대응이 가능해집니다.`,
	`고객, 상품, 전략목표 등 서로 다른 기관의 니즈에 맞추어 최적의 솔루션 셋과 전략을 구축하고, 시장 최고의 전문가 그룹이 독보적인 인사이트를 담아 특화 자문을 함께 제공합니다. 실무현장에 즉시 적용하고 바로 효과를 확인할 수 있습니다.`,
];

export const LendingIntelligenceSection = ({ isTrigger, onChangeVideo }) => {
	const tabList = [
		{ title: "AI 신용평가 모델", value: "0" },
		{ title: "지속적인 최신화", value: "1" },
		{ title: "기관별 큐레이션", value: "2" },
	];
	const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

	// Lending Intelligence
	const [currentVideo, setCurrentVideo] = useState(0);

	return (
		<SectionContainer>
			<ContentContainer>
				<Flex direction={isMobile ? "column" : "row"} w={"100%"} h={"100%"}>
					{!isMobile && (
						<Flex
							w={"50%"}
							direction={"column"}
							align={"center"}
							justify={"center"}
						></Flex>
					)}

					<Flex
						w={isMobile ? "100%" : "50%"}
						direction={"column"}
						align={"center"}
						pt={isMobile ? 40 : 0}
						style={{ position: "relative" }}
					>
						<FadeInToTop duration={"0.2s"} isTrigger={isTrigger}>
							<Flex
								w={isMobile ? "100%" : 480}
								direction={"column"}
								p={24}
								gap={isMobile ? 16 : 24}
							>
								<Flex gap={8} align={"end"}>
									<Image
										src={logo_ll}
										h={isMobile ? 338 * 0.12 : 338 * 0.14}
										w={isMobile ? 1312 * 0.12 : 1312 * 0.14}
									/>
									<Title order={isMobile ? 5 : 4} mb={-3} c={"black"}>
										· 렌딩인텔리전스
									</Title>
								</Flex>
								<Title order={isMobile ? 3 : 1}>
									최신 AI기술로 금융기관의 빈틈없는 리스크관리를 지원하는 솔루션
								</Title>
								{isMobile && (
									<DeviceFrame>
										<ScreenLending
											currentVideo={currentVideo}
											isTrigger={true}
										/>
									</DeviceFrame>
								)}
								<Tabs
									color={"gray.9"}
									defaultValue={tabList[0].value}
									onChange={(value) => {
										let index = parseInt(value);
										onChangeVideo(index);
										setCurrentVideo(index);
									}}
								>
									<Flex direction={"column"} gap={20}>
										<Stack gap={'0'}>
											<Tabs.List style={{position: 'relative', zIndex:9}}>
												{tabList.map((tab, i) => (
													<Tabs.Tab
														size={isMobile ? "xs" : "lg"}
														w={"33.33%"}
														value={tab.value}
														key={i}
													>
														{tab.title}
													</Tabs.Tab>
												))}
											</Tabs.List>
										</Stack>

										{contentList.map((item, i) => (
											<Tabs.Panel value={tabList[i].value}>
												<TextBox size={isMobile ? "md" : "xl"}>{item}</TextBox>
											</Tabs.Panel>
										))}
									</Flex>
								</Tabs>
							</Flex>
						</FadeInToTop>
					</Flex>
				</Flex>
			</ContentContainer>
		</SectionContainer>
	);
};
